import classes from "./OwnModalSubmit.module.css";
const OwnModalSubmit = ({ previewName, previewHandler, submitHandler, isLast }) => {

  return (
    <div className={classes.ownModalSubMitContainer}>
      <button onClick={previewHandler}>
        <img 
          src={require("../../../assets/images/ownmodal/preview_icon.png")}
          alt=""
        />
        {previewName}
      </button>
      {
        isLast && <button onClick={submitHandler}> Submit   <img className='filterDark'
          src={require("../../../assets/images/arrow_icon_gradient.png")}
          alt=""
        /></button>
      }

    </div>
  );
};
export default OwnModalSubmit;
