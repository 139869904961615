import React from 'react';
import OwnModalFoundationDropDown from '../OwnModalFoundationalDropDown/OwnModalFoundationalDropDown';
import classes from './OwnModalFoundationalSubCategoryTabList.module.css';
const OwnModalFoundationalSubCategoryTabList = ({ name, img, handler, activeCategory, subList, subListHandler, isVisible, activeCount, totalCounter }) => {


  const onClickHanlder = (subList) => {
    handler(subList)
  }
  const subSubListHandler = (name) => {
    subListHandler(name)
  }




  return (
    <li className={classes.subCategoryItem}>
      <button className={activeCategory === name ? classes.active : undefined} onClick={onClickHanlder.bind(null, name)}>
        <img src={img} alt={name}  className='filterDark'/>
        <span>{name} {name !== "Cloud Providers" && `(${activeCount}/${totalCounter})`}</span>
      </button>
      {
        isVisible && name === "Cloud Providers" && activeCategory === "Cloud Providers"
          ?
          <OwnModalFoundationDropDown subCategory={subList.subCategory} handler={subSubListHandler} /> : undefined
      }
    </li>
  )
}

export default OwnModalFoundationalSubCategoryTabList;