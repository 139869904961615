import React, { useState, useContext } from 'react';
import { OwnModalContext } from 'pi-screen/own_modal/context/ownModalContext';
import OwnModalFoundationalSubCategoryTabList from './OwnModalFoundationalSubCategoryTabList/OwnModalFoundationalSubCategoryTabList';
import OwnModalFoundationalChips from './OwnModalFoundationalChips/OwnModalFoundationalChips';
import classes from './OwnModalFoundationalSubCategory.module.css';
const OwnModalFoundationalSubCategory = ({ category, activeCategory, activeCategoryHandler, dataHandler, question }) => {
  const ctx = useContext(OwnModalContext);
  const [subList, setSubList] = useState(null);
  const [cloudProviderVisible, setCloudProviderVisible] = useState(false);
  const activeSubListIndex = category.category.findIndex((cat) => cat.name === activeCategory);
  const activeSubList = category.category[activeSubListIndex];
  const setSubListHandler = (name) => {
    const activeSubSubListIndex = activeSubList.subCategory.findIndex((subCat) => subCat.name === name);
    const activeSubSubList = activeSubList.subCategory[activeSubSubListIndex];
    setSubList(activeSubSubList);
    setCloudProviderVisible(false);
  }
  const subCategoryHandler = (subList) => {
    if (subList === "Cloud Providers") {
      setCloudProviderVisible(true);
    } else {
      setCloudProviderVisible(false)
    }
    activeCategoryHandler(subList);
    setSubList(null)
  }
  const chipshandler = (paraSubList, path) => {
    dataHandler(category, activeCategory, paraSubList, subList, path)
  }



  const existingFoundationalDataIndex = ctx.formDetail.findIndex((founData) => founData.question === question);
  const existingModal = ctx.formDetail[existingFoundationalDataIndex];
  const tabCounter = {}
  let tempSubCategory = [];
  if (existingModal) {
    const existingMainCategoryIndex = existingModal.option.findIndex((mainCate) => mainCate.name === category.name);
    if (existingModal.option[existingMainCategoryIndex]) {
      existingModal.option[existingMainCategoryIndex].category.forEach((cat) => {
        tabCounter[cat.name] = cat.subCategory.length
      })
      const existingCategoryIndex = existingModal.option[existingMainCategoryIndex].category.findIndex((exisCat) => exisCat.name === activeCategory);
      const existingCategory = existingModal.option[existingMainCategoryIndex].category[existingCategoryIndex];

      if (existingCategory) {
        if (activeCategory === "Cloud Providers" && subList !== null) {
          const existingSubCategoryIndex = existingCategory.subCategory.findIndex((existSub) => existSub.name === subList.name);
          const existingSubCategory = existingCategory.subCategory[existingSubCategoryIndex];
          if (existingSubCategory) {
            tempSubCategory = existingSubCategory.subList;
          }

        } else {
          tempSubCategory = existingCategory.subCategory.map((exisSub) => exisSub.name)
        }
      }

    }
  }


  return (
    <>
      <ul className={classes.subCategoryList}>
        {
          category.category.map((subCat) => {
            return (
              <OwnModalFoundationalSubCategoryTabList key={subCat.name} name={subCat.name} handler={subCategoryHandler} img={subCat.img} activeCategory={activeCategory} subList={activeSubList} subListHandler={setSubListHandler} isVisible={cloudProviderVisible} activeCount={tabCounter[subCat.name] ? tabCounter[subCat.name] : 0} totalCounter={subCat.subCategory.length} />
            )
          })
        }
      </ul>

      {
        activeCategory !== "Cloud Providers" && <OwnModalFoundationalChips storedChips={tempSubCategory} subList={activeSubList} chipsHandler={chipshandler} />
      }
      {
        activeCategory === "Cloud Providers" && subList ? <OwnModalFoundationalChips storedChips={tempSubCategory} subList={subList} chipsHandler={chipshandler} /> : undefined
      }
    </>
  )
}

export default OwnModalFoundationalSubCategory;

