import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setUpdateEnterprisesModal } from "store/actions";

const EnterprisesUSeCasesModal = ({ type, multiModalActive, data }) => {
  const StoreData = useSelector((data) => data.enterpriseUsecasModalReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Multimodal = [
    {
      name: "Free Text",
      img: "data_augment_",
      img_active: "data_augment_active",
      type: "general",
    },
    {
      name: "SQL",
      img: "sql_",
      img_active: "sql_active",
      type: "general",
    },
    {
      name: "NoSQL",
      img: "nosql_",
      img_active: "nosql_actibe",
      type: "general",
    },
    {
      name: "Image",
      img: "image_",
      img_active: "image_active",
      type: "general",
    },
    {
      name: "Video",
      img: "video_",
      img_active: "video_active",
      type: "general",
    },
    {
      name: "Audio",
      img: "audio_",
      img_active: "audio_Active",
      type: "general",
    },
    {
      name: "HTTP/Webhook",
      img: "http_",
      img_active: "http_active",
      type: "general",
    },
    {
      name: "Message Bus",
      img: "message_bus",
      img_active: "message_bus_active",
      type: "general",
    },
    {
      name: "Website",
      img: "website_",
      img_active: "website_active",
      type: "Website",
    },
  ];
  const SourceModalInitailValue = [
    { Source: "Salesforce", url: [], inpVal: "", category: "general" },
    { Source: "ServiceNow", url: [], inpVal: "", category: "general" },
    { Source: "Sharepoint", url: [], inpVal: "", category: "general" },
    { Source: "MS Dynamics", url: [], inpVal: "", category: "general" },
    { Source: "Snowflakes", url: [], inpVal: "", category: "general" },
    { Source: "BigQuery", url: [], inpVal: "", category: "general" },
    { Source: "Azure Blob", url: [], inpVal: "", category: "general" },
    { Source: "AWS S3", url: [], inpVal: "", category: "general" },
    { Source: "Power BI", url: [], inpVal: "", category: "general" },
    { Source: "Qlik", url: [], inpVal: "", category: "general" },
    { Source: "Tableau", url: [], inpVal: "", category: "general" },
    { Source: "Crawl", url: [], inpVal: "", category: "Website" },
    { Source: "Sitemap", url: [], inpVal: "", category: "Website" },
  ];
  const [SourceModalVal, setSourceModalVal] = useState(SourceModalInitailValue);
  return (
    <section>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered enterprises_usecase_modal">
          <div className="modal-content enterprises_usecase_modal_content">
            <div className="modal-header enterprises_usecase_modal_header">
              {data && (
                <h2 className="modal-title" id="exampleModalLabel">
                  {data} <span>({multiModalActive})</span>
                </h2>
              )}

              <button
                type="button"
                onClick={() => {
                  if (type !== "StoreData") {
                    setSourceModalVal([...SourceModalInitailValue]);
                  }
                }}
                data-bs-dismiss="modal"

              >
                <img className="filterDark"
                  src={require("../../../../assets/images/close icon black.png")}
                  alt=""
                />
              </button>
            </div>
            <div className="modal-body enterprises_usecase_modal_body">
              <div className="table_hoder">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>Url Links</th>
                    </tr>
                  </thead>
                  <tbody>
                    {type === "StoreData"
                      ? StoreData.modalValue.map((item, i) => (
                          <tr key={i}>
                            <td className="data_name">{item.Source}</td>
                            <td>
                              <div className="modal_chip_container">
                                {item.url.map((it, i) => (
                                  <div className="chip" key={it.id}>
                                    <p>{it.name}</p>
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        ))
                      : SourceModalVal.filter((item, i) => {
                          if (multiModalActive === "Website") {
                            return item?.category === "Website";
                          } else {
                            return item?.category !== "Website";
                          }
                        }).map((item, i) => (
                          <tr key={i}>
                            <td>
                              <div className="check_holder">
                                <input
                                  type="checkbox"
                                  readOnly
                                  checked={item.url.length > 0 ? true : false}
                                />
                                <div className="custom_holder">
                                  <span className="checkmark"></span>
                                  <span className="labelTxt">
                                    {item.Source}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="website_form_group form-group">
                                <input
                                  type="text"
                                  className="form_control form-control"
                                  placeholder="www.xyz.com"
                                  name={item.Source}
                                  value={item.inpVal}
                                  onChange={(e) => {
                                    const data = SourceModalVal?.map((val) => {
                                      return val?.Source === item.Source
                                        ? { ...val, inpVal: e.target.value }
                                        : val;
                                    });
                                    setSourceModalVal([...data]);
                                  }}
                                />
                                <button
                                  className="add_url_links"
                                  onClick={() => {
                                    const data = SourceModalVal?.map((val) => {
                                      console.log(val.Source, item.Source);
                                      const getval = SourceModalVal.find(
                                        (it) => it?.Source === item.Source
                                      );
                                      if (getval.inpVal === "") return null;
                                      if (
                                        getval != undefined &&
                                        getval != null
                                      ) {
                                        return val?.Source === item.Source
                                          ? {
                                              ...val,
                                              inpVal: "",
                                              url: [
                                                ...val.url,
                                                {
                                                  id: Number(new Date()),
                                                  name: getval.inpVal,
                                                },
                                              ],
                                            }
                                          : val;
                                      }
                                      return val;
                                    });
                                    if (data.every((it) => it !== null)) {
                                      setSourceModalVal([...data]);
                                    }
                                    console.log(data);
                                  }}
                                >
                                  + Add URL
                                </button>
                              </div>
                              <div className="modal_chip_container">
                                {item.url.map((url) => (
                                  <div className="chip" key={url.id}>
                                    <p>{url.name}</p>
                                    <button
                                      className="close urlClose"
                                      onClick={() => {
                                        const data = SourceModalVal.map(
                                          (modalVal, i) => {
                                            return modalVal?.Source ===
                                              item.Source
                                              ? {
                                                  ...modalVal,
                                                  url: modalVal.url.filter(
                                                    (uids) => uids.id !== url.id
                                                  ),
                                                }
                                              : modalVal;
                                          }
                                        );
                                        if (data) setSourceModalVal([...data]);
                                      }}
                                    >
                                      <img className="filterDark"
                                        src={require("../../../../assets/images/close_icon_black.png")}
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              
              </div>
              {type === "SourceModal" && (
                  <div className="submit_container">
                    <button
                      className="half_border_button submit generateModal"
                      data-bs-dismiss="modal"
                      disabled={
                        SourceModalVal.filter(
                          (item, i) => item.url.length !== 0
                        ).length > 0
                          ? false
                          : true
                      }
                      onClick={() => {
                        const fildata = SourceModalVal.filter(
                          (item, i) => item.url.length !== 0
                        );
                        console.log(fildata);
                        if (fildata.length > 0) {
                          dispatch(
                            setUpdateEnterprisesModal({
                              modalName: multiModalActive,
                              modalValue: fildata,
                            })
                          );
                          localStorage.setItem(
                            "usecaseTab",
                            "Enterprises Use Cases"
                          );
                          navigate(multiModalActive);
                        }
                      }}
                    >
                      Generate 
                      <img className="filterDark"
                        src={require("../../../../assets/images/arrow_icon_gradient.png")}
                        alt=""
                      />
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EnterprisesUSeCasesModal;
