import FileDropPreview from "component/filedrop_preview";
import React from "react";

const FileDropPopOver = ({
  SideSlideView,
  SetSideSlideView,
  uploadedFiles,
  setUpoloadedFiles,
}) => {
  return (
    <div className={`slideOver ${SideSlideView ? "slideActive" : ""}`}>
      <div
        className={`slideTrigger subBtn ${
          SideSlideView ? "slideTriggerHide" : ""
        }`}
        onClick={() => SetSideSlideView(true)}
      >
        <p>Add Or Remove Documents</p>
      </div>
      <div className="sliderContent">
        <div className={"slideClose"} onClick={() => SetSideSlideView(false)}>
          <img
            src={require("../../assets/images/close icon black.png")}
            alt="close"
          />
        </div>
        <div className="slideFileInput">
          <FileDropPreview
            uploadedFiles={uploadedFiles}
            setUpoloadedFiles={setUpoloadedFiles}
          />

          <div className="qa_docs_generate">
            <button
              className="half_border_button"
              id="useCase_generateBtn"
              onClick={() => SetSideSlideView(false)}
            >
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileDropPopOver;
