import React from "react";
import Enabiling from "./enabling";
import HomeProduct from "./product";
import HomeEnterprises from "./home_enterprises";
import AboutUs from "./about_us";
import HomeBanner from "./banner";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const index = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Home",
    });
  }, []);
  return (
    <>
      <HomeBanner />
      <Enabiling />
      <HomeProduct />
      <HomeEnterprises />
      <AboutUs />
      <section>
        <div className="footer_container">
          <div className="footer_content">
            <h2>
              {" "}
              <img src={require("../../assets/images/piLogo.png")} alt="" />
            </h2>
          </div>

          <div className="footer_Enterprise_content">
            <div className="head">
              <h3>Enterprise Use Cases</h3>
            </div>
            <ul>
              <li>
                <a href="#enterprise_Navigate">Retail</a>
              </li>
              <li>
                <a href="#enterprise_Navigate">Consumer Goods</a>
              </li>
              <li>
                <a href="#enterprise_Navigate">
                  Banking ,Finance and Insurance{" "}
                </a>
              </li>
              <li>
                <a href="#enterprise_Navigate">Energy & Utilities </a>
              </li>
              <li>
                <a href="#enterprise_Navigate">
                  Communication, Media & Technology
                </a>
              </li>
              <li>
                <a href="#enterprise_Navigate">Healthcare</a>
              </li>
              <li>
                <a href="#enterprise_Navigate">LifeScience</a>
              </li>
              <li>
                <a href="#enterprise_Navigate">Travel &amp; Hospitality</a>
              </li>
              <li>
                <a href="#enterprise_Navigate">Manufacturing & Logistics</a>
              </li>
              <li>
                <a href="#enterprise_Navigate">Government</a>
              </li>
              <li>
                <a href="#enterprise_Navigate">Telecommunications</a>
              </li>
            </ul>
          </div>
          <div className="footer_content">
            <div className="head">
              <h3>Quick Links</h3>
            </div>
            <ul>
              <li>
                <a href="#enabling_navigate">How we are enabling</a>
              </li>
              <li>
                <Link to="Login">Playground</Link>
              </li>
              <li>
                <a href="#">Blogs</a>
              </li>
              <li>
                <a href="#about_us_navigate">About Us</a>
              </li>
            </ul>
          </div>
          <div className="footer_content social">
            <div className="head">
              <h3>Social</h3>
            </div>
            {/* <ul>
              <li>
                <a href="">Twitter</a>
              </li>
              <li>
                <a href="">Facebook</a>
              </li>
              <li>
                <a href="">Github</a>
              </li>
              <li>
                <a href="">Discord</a>
              </li>
            </ul> */}
            <ul>
              <li>
                <a href="">
                  <img
                    src={require("../../assets/images/Home_Quicklink_Twitter_Black_1.png")}
                    alt="Scroll_Icon"
                  />
                  <img
                    src={require("../../assets/images/Home_Quicklink_Twitter_Blue_1.png")}
                    alt="Scroll_Icon"
                  />
                </a>
              </li>
              <li>
                <a href="">
                  {" "}
                  <img
                    src={require("../../assets/images/Home_Quicklink_Facebook_Black_2.png")}
                    alt="Scroll_Icon"
                  />
                  <img
                    src={require("../../assets/images/Home_Quicklink_Facebook_Blue_2.png")}
                    alt="Scroll_Icon"
                  />
                </a>
              </li>
              <li>
                <a href="">
                  {" "}
                  <img
                    src={require("../../assets/images/Home_Quicklink_GitHub_Black_3.png")}
                    alt="Scroll_Icon"
                  />
                  <img
                    src={require("../../assets/images/Home_Quicklink_GitHub_Blue_3.png")}
                    alt="Scroll_Icon"
                  />
                </a>
              </li>
              <li>
                <a href="">
                  {" "}
                  <img
                    src={require("../../assets/images/Home_Quicklink_Discard_Black_4.png")}
                    alt="Scroll_Icon"
                  />
                  <img
                    src={require("../../assets/images/Home_Quicklink_Discard_Blue_4.png")}
                    alt="Scroll_Icon"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <footer>
        <div className="footer">
          <div className="footer_left_content">
            <p>Copyrights PiProjections 2023 . All Rights Reserved</p>
          </div>
          <div className="footer_right_content">
            <a href="#">Terms & conditions </a>
            <a href="#">Privacy Policy </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default index;
