import React from "react";

const PageNotFound = () => {
  return (
    <div className="error_Screen">
      <div className="error_screen_holder">
        <h2>Error 404 Page Not Found</h2>
      </div>
    </div>
  );
};

export default PageNotFound;
