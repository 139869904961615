import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const TextToVideo = () => {
  const [resultView, setResultView] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="use_case_ex_container p-0">
        <div className="text_to_image_container">
          <div className="input_container">
            <div className="form-group form_group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter the text to convert text to Video" autoFocus
              />
            </div>
          </div>
          <div className="text_to_image_selectionArea"></div>
          <div className="generate_btn_container">
            <button
              className="half_border_button"
              onClick={() => setResultView(true)}
            >
              Generate
            </button>
          </div>
          {resultView && (
            <div className="image_preview_container">
              <div className="image_preview_header">
                <h3>VIDEO</h3>
                <button className="closed" onClick={() => setResultView(false)}>
                  <img className="filterDark"
                    src={require("../../../../assets/images/close_icon_black.png")}
                    alt="close_icon_black"
                  />
                </button>
              </div>
              <div className="image_preview_body">
                <h2>Artificial Inteligence</h2>

                <div className="preview_images_holder">
                  <div className="img_holder">
                    <img
                      src={require("../../../../assets/images/img_1.png")}
                      alt="img_11"
                    />
                    <h3>Artificial Inteligence</h3>
                    <button className="download">
                      <img
                        src={require("../../../../assets/images/download_icon.png")}
                        alt="download_icon"
                      />
                    </button>
                    <button className="play">
                      <img
                        src={require("../../../../assets/images/video_play_icon.png")}
                        alt="video_play_icon"
                      />
                    </button>
                  </div>
                  <div className="img_holder">
                    <img
                      src={require("../../../../assets/images/img_2.png")}
                      alt="img_2"
                    />
                    <h3>Artificial Inteligence</h3>
                    <button className="download">
                      <img
                        src={require("../../../../assets/images/download_icon.png")}
                        alt="download_icon"
                      />
                    </button>
                    <button className="play">
                      <img
                        src={require("../../../../assets/images/video_play_icon.png")}
                        alt="video_play_icon"
                      />
                    </button>
                  </div>
                  <div className="img_holder">
                    <img
                      src={require("../../../../assets/images/img_5.png")}
                      alt="img_3"
                    />
                    <h3>Artificial Inteligence</h3>
                    <button className="download">
                      <img
                        src={require("../../../../assets/images/download_icon.png")}
                        alt="download_icon"
                      />
                    </button>
                    <button className="play">
                      <img
                        src={require("../../../../assets/images/video_play_icon.png")}
                        alt="video_play_icon"
                      />
                    </button>
                  </div>
                  <div className="img_holder">
                    <img
                      src={require("../../../../assets/images/img_4.png")}
                      alt="img_4"
                    />
                    <h3>Artificial Inteligence</h3>
                    <button className="download">
                      <img
                        src={require("../../../../assets/images/download_icon.png")}
                        alt="download_icon"
                      />
                    </button>
                    <button className="play">
                      <img
                        src={require("../../../../assets/images/video_play_icon.png")}
                        alt="video_play_icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TextToVideo;
