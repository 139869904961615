import React, { useState, useContext, useRef, useEffect } from "react";
import OwnModalContext from "pi-screen/own_modal/context/ownModalContext";
import OwnModalModal from "pi-screen/own_modal/Modal/OwnModalModal";
import OwnModalPreviewModal from "./OwnModalPreviewModal/OwnModalPreviewModal";
import OwnModalListItem from "./OwnModalListItem/OwnModalListItem";
import OwnModalSubmit from "../../OwnModalSubmit/OwnModalSubmit";
import classes from "./ownModalContent.module.css";
const OwnModalContent = ({ data, pageNoter }) => {
  const scroller = useRef();
  useEffect(() => {
    scroller.current.scrollIntoView();
  }, [data]);
  const ctx = useContext(OwnModalContext);
  const [modalShow, setModalShow] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [forPreview, setForPreview] = useState(null);
  const formDetail = [...ctx.formDetail];
  let content = data.filter((content, i) => {
    if (i !== 0) {
      return content;
    }
  });

  const onPreview = () => {
    // const existingQuestions = formDetail.map((formDet) => formDet.question);
    // const checker = content.map(element => {
    //   if (existingQuestions.includes(element.question)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    // const checkResult = checker.every((check) => check === true);
    // if (checkResult) {

    // }
    let previewData = [];
    content.forEach((element) => {
      const modalIndex = formDetail.findIndex(
        (form) => form.question === element.question
      );
      const existingModal = formDetail[modalIndex];
      if (existingModal) {
        previewData.push(existingModal);
      }
    });
    // if (previewData.length > 0) {
    //   setForPreview(previewData);
    //   setModalShow(true)
    // }
    setForPreview(previewData);
    setModalShow(true);
  };
  const onPreviewAll = () => {
    const existingQuestions = formDetail.map((formDet) => formDet.question);
    const checker = content.map((element) => {
      if (existingQuestions.includes(element.question)) {
        return true;
      } else {
        return false;
      }
    });
    const checkResult = checker.every((check) => check === true);
    if (checkResult) {
      setModalShow(true);
    }
  };
  const submitHandler = () => {
    const existingQuestions = formDetail.map((formDet) => formDet.question);
    const checker = content.map((element) => {
      if (existingQuestions.includes(element.question)) {
        return true;
      } else {
        return false;
      }
    });
    const checkResult = checker.every((check) => check === true);
    if (checkResult) {
      setModalShow(true);
      setOnSubmit(true);
    }
  };
  const modalcloser = () => {
    setModalShow(false);
    setOnSubmit(false);
  };

  return (
    <>
      {modalShow && (
        <OwnModalModal>
          <OwnModalPreviewModal
            closeHandler={modalcloser}
            data={pageNoter === "last" ? formDetail : forPreview}
            isSubmit={onSubmit}
          />
        </OwnModalModal>
      )}
      <div className={classes.modal_QA_content_container}>
        <ul className={classes.modal_QA_Content_list} ref={scroller}>
          {content.map((contentData) => (
            <OwnModalListItem contentData={contentData} key={contentData.id} />
          ))}
        </ul>
        {pageNoter !== "last" && (
          <OwnModalSubmit
            previewName="Preview"
            previewHandler={onPreview}
            submitHandler={submitHandler}
            isLast={false}
          />
        )}
        {pageNoter === "last" && (
          <OwnModalSubmit
            previewName="Preview All"
            previewHandler={onPreviewAll}
            submitHandler={submitHandler}
            isLast={true}
          />
        )}
      </div>
    </>
  );
};

export default OwnModalContent;
