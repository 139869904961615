import classes from './OwnModalFoundationalDropDown.module.css';
const OwnModalFoundationDropDown = ({ subCategory, handler }) => {
  const subListHandler = (subList) => {
    handler(subList)
  }
  return (
    <ul className={classes.ownModalSubCategory_DropDown}>
      {subCategory.map((subCat) => <li className={classes.ownModalSubCategory_DropDown_list} key={subCat.name}><button onClick={subListHandler.bind(null, subCat.name)}>{subCat.name}</button></li>)}
    </ul>
  )
}

export default OwnModalFoundationDropDown;