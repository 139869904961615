import classes from './OwnModalFormHeader.module.css';
const OwnModalFormHeader = (props) => {
  return (
    <div className={classes.formHeader}>
      <h6>{props.heading}</h6>
      <button onClick={props.closeHandler}>
        <img className='filterDark' src={require('../../../assets/images/close_icon_black.png')} />
      </button>
    </div>
  )
}

export default OwnModalFormHeader;