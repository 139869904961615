import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { setUpdateEnterprisesModal } from "store/actions";
import { useParams } from "react-router-dom";
import EnterprisesUSeCasesModal from "./enterprises_usecases_modal";
const EnterprisesUsecases = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useParams();
  // console.log(data);
  const Multimodal = [
    {
      name: "Free Text",
      img: "data_augment_",
      img_active: "data_augment_active",
      type: "general",
    },
    {
      name: "SQL",
      img: "sql_",
      img_active: "sql_active",
      type: "general",
    },
    {
      name: "NoSQL",
      img: "nosql_",
      img_active: "nosql_actibe",
      type: "general",
    },
    {
      name: "Image",
      img: "image_",
      img_active: "image_active",
      type: "general",
    },
    {
      name: "Video",
      img: "video_",
      img_active: "video_active",
      type: "general",
    },
    {
      name: "Audio",
      img: "audio_",
      img_active: "audio_Active",
      type: "general",
    },
    {
      name: "HTTP/Webhook",
      img: "http_",
      img_active: "http_active",
      type: "general",
    },
    {
      name: "Message Bus",
      img: "message_bus",
      img_active: "message_bus_active",
      type: "general",
    },
    {
      name: "Website",
      img: "website_",
      img_active: "website_active",
      type: "Website",
    },
  ];
  const [multiModalActive, setMultiModalActive] = useState("");

  const SourceModalInitailValue = [
    { Source: "Salesforce", url: [], inpVal: "", category: "general" },
    { Source: "ServiceNow", url: [], inpVal: "", category: "general" },
    { Source: "Sharepoint", url: [], inpVal: "", category: "general" },
    { Source: "MS Dynamics", url: [], inpVal: "", category: "general" },
    { Source: "Snowflakes", url: [], inpVal: "", category: "general" },
    { Source: "BigQuery", url: [], inpVal: "", category: "general" },
    { Source: "Azure Blob", url: [], inpVal: "", category: "general" },
    { Source: "AWS S3", url: [], inpVal: "", category: "general" },
    { Source: "Power BI", url: [], inpVal: "", category: "general" },
    { Source: "Qlik", url: [], inpVal: "", category: "general" },
    { Source: "Tableau", url: [], inpVal: "", category: "general" },
    { Source: "Crawl", url: [], inpVal: "", category: "Website" },
    { Source: "Sitemap", url: [], inpVal: "", category: "Website" },
  ];
  const [SourceModalVal, setSourceModalVal] = useState(SourceModalInitailValue);

  return (
    <>
      <div className="sub_nav ad_content">
        <div className="sub_nav_left_Content">
          <div className="breadcrums">
            <Link
              to="/usecases"
              onClick={() => {
                localStorage.setItem("usecaseTab", "Enterprises Use Cases");
              }}
            >
              Enterprises Usecases
            </Link>
            {">"}
            <a href="#">{data}</a>
          </div>
          <h3 className="use_case_detailer">
            <img className="filterDark"
              src={require("../../../assets/images/QA_black_icon.png")}
              alt="QA_black_icon"
            />
            {data}
          </h3>
        </div>
        <div className="sub_nav_right_Content">
          <p>Create Your Own Model</p>

          <div className="btn_container">
            <button
              className="half_border_button"
              type="button"
              aria-expanded="false"
              onClick={() => navigate("ownModel")}
            >
              <img
                className="icon"
                src={require("../../../assets/images/q_a_icon.png")}
                alt=""
              />
              <span>Q&A Based</span>
              <img
                className="arrow"
                src={require("../../../assets/images/arrow_icon_gradient.png")}
                alt=""
              />
            </button>
            <button
              className="half_border_button"
              type="button"
              aria-expanded="false"
              onClick={() => navigate("/uiDriven")}
            >
              <img
                className="icon"
                src={require("../../../assets/images/UI_icon.png")}
                alt=""
              />
              <span>UI Driven</span>
              <img
                className="arrow"
                src={require("../../../assets/images/arrow_icon_gradient.png")}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <div className="use_case_ex_container">
        <div className="enterprises_usecase_card_container">
          {Multimodal.map((item, i) => (
            <div
              key={i}
              className="enterprises_usecase_card"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                setMultiModalActive(item.name);
              }}
            >
              <div className="img_holder">
                <img
                  src={require(`../../../assets/images/Multimodels_Blue_${
                    i + 1
                  }.png`)}
                  alt=""
                />
                <img
                  src={require(`../../../assets/images/Multimodels_Ash_${
                    i + 1
                  }.png`)}
                  alt=""
                />
              </div>
              <h4>{item.name}</h4>
            </div>
          ))}
        </div>
      </div>
      <EnterprisesUSeCasesModal type={"SourceModal"} multiModalActive={multiModalActive} data={data}/>
      {/* <section>
        <div
          className="modal fade"
          data-bs-backdrop="static"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered enterprises_usecase_modal">
            <div className="modal-content enterprises_usecase_modal_content">
              <div className="modal-header enterprises_usecase_modal_header">
                <h2 className="modal-title" id="exampleModalLabel">
                  {data} <span>({multiModalActive})</span>
                </h2>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    setSourceModalVal([...SourceModalInitailValue])
                  }
                >
                  <img
                    src={require("../../../assets/images/close icon black.png")}
                    alt=""
                  />
                </button>
              </div>
              <div className="modal-body enterprises_usecase_modal_body">
               
                <div className="table_hoder">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Source</th>
                        <th>Url Links</th>
                      </tr>
                    </thead>
                    <tbody>
                      {SourceModalVal.filter((item, i) => {
                        if (multiModalActive === "Website") {
                          return item?.category === "Website";
                        } else {
                          return item?.category !== "Website";
                        }
                      }).map((item, i) => (
                        <tr key={i}>
                          <td>
                            <div className="check_holder">
                              <input
                                type="checkbox"
                                readOnly
                                checked={item.url.length > 0 ? true : false}
                              />
                              <div className="custom_holder">
                                <span className="checkmark"></span>
                                <span className="labelTxt">{item.Source}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="website_form_group form-group">
                              <input
                                type="text"
                                className="form_control form-control"
                                placeholder="www.xyz.com"
                                name={item.Source}
                                value={item.inpVal}
                                onChange={(e) => {
                                  const data = SourceModalVal?.map((val) => {
                                    return val?.Source === item.Source
                                      ? { ...val, inpVal: e.target.value }
                                      : val;
                                  });
                                  setSourceModalVal([...data]);
                                }}
                              />
                              <button
                                className="add_url_links"
                                onClick={() => {
                                  const data = SourceModalVal?.map((val) => {
                                    console.log(val.Source, item.Source);
                                    const getval = SourceModalVal.find(
                                      (it) => it?.Source === item.Source
                                    );
                                    if (getval.inpVal === "") return null;
                                    if (getval != undefined && getval != null) {
                                      return val?.Source === item.Source
                                        ? {
                                            ...val,
                                            inpVal: "",
                                            url: [
                                              ...val.url,
                                              {
                                                id: Number(new Date()),
                                                name: getval.inpVal,
                                              },
                                            ],
                                          }
                                        : val;
                                    }
                                    return val;
                                  });
                                  if (data.every((it) => it !== null)) {
                                    setSourceModalVal([...data]);
                                  }
                                  console.log(data);
                                }}
                              >
                                + Add URL 
                              </button>
                            </div>
                            <div className="modal_chip_container">
                              {item.url.map((url) => (
                                <div className="chip" key={url.id}>
                                  <p>{url.name}</p>
                                  <button
                                    className="close urlClose"
                                    onClick={() => {
                                      const data = SourceModalVal.map(
                                        (modalVal, i) => {
                                          return modalVal?.Source ===
                                            item.Source
                                            ? {
                                                ...modalVal,
                                                url: modalVal.url.filter(
                                                  (uids) => uids.id !== url.id
                                                ),
                                              }
                                            : modalVal;
                                        }
                                      );
                                      if (data) setSourceModalVal([...data]);
                                    }}
                                  >
                                    <img
                                      src={require("../../../assets/images/close_icon_black.png")}
                                      alt=""
                                    />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="submit_container">
                  <button
                    className="half_border_button submit generateModal"
                    data-bs-dismiss="modal"
                    disabled={
                      SourceModalVal.filter((item, i) => item.url.length !== 0)
                        .length > 0
                        ? false
                        : true
                    }
                    onClick={() => {
                      const fildata = SourceModalVal.filter(
                        (item, i) => item.url.length !== 0
                      );
                      console.log(fildata);
                      if (fildata.length > 0) {
                        dispatch(
                          setUpdateEnterprisesModal({
                            modalName: multiModalActive,
                            modalValue: fildata,
                          })
                        );
                        localStorage.setItem(
                          "usecaseTab",
                          "Enterprises Use Cases"
                        );
                        navigate(multiModalActive);
                      }
                    }}
                  >
                    Generate
                    <img
                      src={require("../../../assets/images/arrow_icon_gradient.png")}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default EnterprisesUsecases;
