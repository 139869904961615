import classes from './OwnModalPreviewListOption.module.css';

const OwnModaPreviewListOptions = (data) => {
  const { data: option } = data;

  return (
    <ul className={classes.categoryList}>
      {
        option.map((dat ,indx) => {
          return (
            <li key={indx} className={classes.categoryItem}>
              <div className={classes.forOThers}>
                <p>{dat.name}</p>
                {
                  typeof dat["subOptions"] === "string"
                  &&
                  <p>: {dat["subOptions"]}</p>
                }

              </div>

              {
                dat["subOptions"]
                &&
                (typeof dat["subOptions"] !== "string"
                  &&
                  <ul className={classes.dataBaseList}>
                    {
                      Object.keys(dat["subOptions"]).map((key) => {
                        return (
                          <li className={classes.dataBaseListItem}>
                            <p>dat{key}</p>

                            <p>: {dat["subOptions"][key]}</p>
                          </li>
                        )


                      })
                    }
                  </ul>)

              }


              {
                dat["subCategory"] &&
                <ul className={classes.directSubCategory}>
                  {
                    <li className={classes.directSubCategoryItem}>{dat["subCategory"].join(', ')}</li>
                  }
                </ul>
              }

              {
                dat["files"] &&
                <ul className={classes.directCategory}>
                  {
                    dat["files"].map((file) => {
                      return (
                        <li className={classes.directCategoryItem}><p>{file.data}</p></li>
                      )
                    })

                  }
                </ul>
              }
              {
                dat["category"] &&

                <ul className={classes.directCategory}>
                  {
                    dat["category"].map((cat) => {
                      return (
                        <li className={classes.directCategoryItem}>
                          <p>{cat.name}</p>
                          <ul className={classes.subCategory}>
                            {
                              cat.subCategory.map((sub) => {
                                return (
                                  <li className={classes.subCategoryItem}>
                                    <p>{sub.name}</p>
                                    {
                                      sub["subList"].length !== 0 &&
                                      <ul className={classes.subList}>
                                        <li className={classes.subListItem}>{sub["subList"].join(', ')}</li>
                                      </ul>
                                    }
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </li>
                      )
                    })
                  }
                </ul>
              }
            </li>
          )
        })
      }
    </ul >

  )



}

export default OwnModaPreviewListOptions;