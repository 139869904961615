import { useContext } from 'react';
import OwnModalContext from '../context/ownModalContext';
import classes from './ownModalHeader.module.css';
const OwnModalHeader = ({ setPagination, pageNoter, data }) => {
  const ctx = useContext(OwnModalContext);
  const actualData = data.filter((dat, idx) => idx !== 0);
  const paginationHandler = (direction) => {
    const existingQuestions = ctx.formDetail.map((formDet) => formDet.question);
    const checker = actualData.map(element => {
      if (existingQuestions.includes(element.question)) {
        return true;
      } else {
        return false;
      }
    });
    const checkResult = checker.every((check) => check === true);
    setPagination(direction, checkResult)
  }

  return (
    <div className={classes.header}>
      <h2>Create Your Own Model - Q&A Based</h2>
      <div className={classes.navigators}>
        <button className={classes.navigator_btn} onClick={paginationHandler.bind(null, "prev")} disabled={pageNoter === 'first'}>
          < img
            src={`${pageNoter === 'first' ? require("../../../assets/images/ownmodal/DeactiveArrow_Icon.png") : require("../../../assets/images/ownmodal/PrevArraow.png")}`}
            alt="arrow"
          />
        </button>
        <button className={classes.navigator_btn} onClick={paginationHandler.bind(null, "next")} disabled={pageNoter === 'last'}>
          <img src={`${pageNoter === 'last' ? require("../../../assets/images/ownmodal/newDefectiveArrow.png") : require("../../../assets/images/ownmodal/Arrow_Icon.png")}`} alt="arrow" />
        </button>
      </div>
    </div >
  )
}

export default OwnModalHeader;

