import React, { useEffect } from "react";
import { Outlet } from "react-router";
import PiHomeNav from "../pi-navs/pi_home_nav"
const HomeLayout = () => {
  useEffect(() => {
    const hash = window.location.hash;
    console.log(hash);
    if (hash) {
      const element = document.getElementById(hash.substr(1));
      if (element) {
        console.log(element);
        element.scrollIntoView();
      }
    }
  }, []);
  return (
    <div>
      
      <PiHomeNav />
      <Outlet />
    </div>
  );
};

export default HomeLayout;
