import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Login = () => {
  const navigate = useNavigate();
  const [EmailType, setEmailType] = useState("password");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Login",
    });
  }, []);
  return (
    <section className="home_res_log">
      <div className="login_container">
        <div className="login_content_container">
          <div className="login_header">
            <h3 className="login">
              Sign in to <span>PiProjections!</span>
            </h3>
            <p>Accelerate AI for your enterprise</p>
          </div>
          <div className="login_Form">
            <form action="" id="login_form">
              <div className="form-group form_group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                />
                <small></small>
              </div>
              <div className="form-group form_group">
                <input
                  type={EmailType}
                  className="form-control"
                  placeholder="Password"
                />
                <small></small>
                <button
                  className="password_type"
                  type="button"
                  onClick={() => {
                    EmailType === "password"
                      ? setEmailType("text")
                      : setEmailType("password");
                  }}
                >
                  <img
                    src={require(`../../assets/images/type_${EmailType}.png`)}
                    alt="eye_icon"
                  />
                </button>
              </div>
              <div className="keep_me_forget">
                <div className="keep_login">
                  <input type="checkbox" id="Keeplogin" />
                  <label htmlFor="Keeplogin">Keep Me Logged In</label>
                </div>
                <a href="#">Forget Password?</a>
              </div>
              <div className="submit">
                <button
                  type="button"
                  className="half_border_button"
                  onClick={() => navigate("/usecases")}
                >
                  Continue
                </button>
              </div>
            </form>
            <div className="login_with">
              <p>Or Login With</p>
            </div>
            <div className="oAuth_container">
              <div className="auth_logo">
                <img
                  src={require("../../assets/images/google_icon.png")}
                  alt="google_icon"
                />
              </div>
              <div className="auth_logo">
                <img
                  src={require("../../assets/images/microsoft_icon.png")}
                  alt="microsoft_icon"
                />
              </div>
              <div className="auth_logo">
                <img
                  src={require("../../assets/images/apple_icon.png")}
                  alt="apple_icon"
                />
              </div>
              <div className="auth_logo">
                <img
                  src={require("../../assets/images/github_icon.png")}
                  alt="apple_icon"
                />
              </div>
            </div>
            <div className="sign_up">
              <p>
                Interested to see more? &nbsp;
                <Link to="/Register">Sign Up Now</Link>
              </p>
            </div>
          </div>
        </div>
        <div className="res_log_footer">
          <div className="footer_left_content">
            <p>Copyright &copy; 2023 PiProjections. All Rights Reserved</p>

            {/* <p>
              Copyright &copy; 2023 
              <span className="blue_text">Pi</span>Projections. All Rights
              Reserved
            </p> */}
          </div>
          <div className="footer_right_content">
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
