import OwnModalFormHeader from "pi-screen/own_modal/OwnModalUI/OwnModalFormHeader";
import OwnModalSubmitBtn from "pi-screen/own_modal/OwnModalUI/OwnModalSubmitBtn";
import OwnModalPreviewModalLists from "./OwnModalPreviewModalLists/OwnModalPreviewModalLists";
import classes from './OwnModalPreviewModal.module.css';
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
const OwnModalPreviewModal = ({ closeHandler, data, isSubmit }) => {
  console.log(data);
  const navigate = useNavigate()
  const onSubmit = () => {

    navigate("/enterprises")
  }

  const theme= useSelector((state)=>state.PiThemeReducer.theme)
  console.log(theme);
  return (
    <div className={`${classes.OwnModalPreviewModal } ${classes[theme]}` } >
      <OwnModalFormHeader heading="Preview" closeHandler={closeHandler} />
      <div className={classes.previewListWrapper}>
        <ul className={classes.OwnModalList}>
          {
            data.map((dat) => {
              console.log(dat);
              return <OwnModalPreviewModalLists key={dat.question} data={dat} />
            })
          }
        </ul>
      </div>
      {
        isSubmit && <OwnModalSubmitBtn name={"Confirm"} handler={onSubmit} />
      }

    </div>

  )
}


export default OwnModalPreviewModal;