import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";

const PiHomeNav = () => {
  const theme = useSelector((state) => state.PiThemeReducer.theme);

  const [scrolling, SetScrolling] = useState(false);
  useEffect(() => {
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  function onScroll() {
    if (
      document.body.scrollTop >= 100 ||
      document.documentElement.scrollTop >= 100
    ) {
      SetScrolling(true);
    } else {
      SetScrolling(false);
    }
  }
  const [activeSection, setActiveSection] = useState("");
  const sections = [
    "landing_container",
    "enabling_navigate",
    "Product_Navigate",
    "enterprise_Navigate",
    "about_us_navigate",
  ]; // Update with your section IDs
  const observerRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );
    sections.forEach((section) => {
      const target = document.getElementById(section);
      if (target) {
        observer.observe(target);
      }
    });

    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [sections]);

  const navLinksRef = useRef(null);

  const handleClick = () => {
    const navLinks = navLinksRef.current;
    console.log(navLinks);

    if (navLinks.scrollHeight) {
      navLinks.style.height = `${navLinks.scrollHeight}px`;
    } else {
      navLinks.style.height = 0;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      const navLinks = navLinksRef.current;
      if (window.innerWidth <= 992 && navLinks.clientHeight) {
        navLinks.style.height = 0;
      }
    };

    const handleResize = () => {
      const navLinks = navLinksRef.current;
      if (window.innerWidth >= 992 && navLinks.clientHeight) {
        navLinks.style.height = "unset";
      }
      // Uncomment the below code block if you need to handle resize event for screens below 992 width.
      // if (window.innerWidth <= 992 && navLinks.clientHeight) {
      //   navLinks.style.height = 0;
      // }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const Location = useLocation();
  useEffect(() => {
    console.log(Location);
    if (Location.pathname === "/Login" || Location.pathname === "/Register") {
      setActiveSection("Playground");
    }
  }, []);
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme === "Light");
  }, []);
  return (
    <header className="home">
      <nav>
        <div className={`nav_bar ${scrolling ? "fixTop" : ""}`}>
          <div className="logo_">
            {Location.pathname === "/Login" ||
            Location.pathname === "/Register" ? (
              <a href="/#">
                <img src={require("../../assets/images/piLogo.png")} alt="" />
              </a>
            ) : (
              <a href="/#">
                <img src={require("../../assets/images/piLogo.png")} alt="" />
              </a>
            )}
          </div>
          <button className="ham-icon" onClick={handleClick}>
            <img
              src={require("../../assets/images/ham_Menu.png")}
              alt="ham_Menu"
            />
          </button>
          <ul className="nav_links" ref={navLinksRef}>
            <li>
              <a
                href="/#enabling_navigate"
                className={
                  activeSection === "enabling_navigate" ? "active" : ""
                }
              >
                How we are enabling
              </a>
            </li>
            <li>
              <a
                href="/#Product_Navigate"
                className={activeSection === "Product_Navigate" ? "active" : ""}
              >
                Products & Use Cases
              </a>
            </li>
            <li>
              <a
                href="/#enterprise_Navigate"
                className={
                  activeSection === "enterprise_Navigate" ? "active" : ""
                }
              >
                Enterprise Use Cases
              </a>
            </li>
            <li>
              <NavLink
                to="/Login"
                // activeclassname={"active"}
                className={activeSection === "Playground" ? "active" : ""}
                onClick={() => setActiveSection("")}
              >
                Playground
              </NavLink>
            </li>
            <li>
              <a
                href="/#about_us_navigate"
                className={
                  activeSection === "about_us_navigate" ? "active" : ""
                }
              >
                About Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default PiHomeNav;
