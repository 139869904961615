import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { setPiTheme } from "store/actions";
const PiNav = () => {
  const navigate = useNavigate();
  const navLinksRef = useRef(null);
  const theme = useSelector((state) => state.PiThemeReducer.theme);

  const dispatch = useDispatch();
  const handleClick = () => {
    const navLinks = navLinksRef.current;
    console.log(navLinks);

    if (navLinks.scrollHeight) {
      navLinks.style.height = `${navLinks.scrollHeight}px`;
    } else {
      navLinks.style.height = 0;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      const navLinks = navLinksRef.current;
      if (window.innerWidth <= 992 && navLinks.clientHeight) {
        navLinks.style.height = 0;
      }
    };

    const handleResize = () => {
      const navLinks = navLinksRef.current;
      if (window.innerWidth >= 992 && navLinks.clientHeight) {
        navLinks.style.height = "unset";
      }
      // Uncomment the below code block if you need to handle resize event for screens below 992 width.
      // if (window.innerWidth <= 992 && navLinks.clientHeight) {
      //   navLinks.style.height = 0;
      // }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <header className="usecase">
        <nav>
          <div className="logo_container">
            <Link to={"/usecases"}>
              <img
                src={require(`../../assets/images/pi_logo_${theme}.png`)}
                alt="Logo"
              />
            </Link>
          </div>
          <button className="ham-icon" onClick={handleClick}>
            <img
              src={require("../../assets/images/ham_Menu.png")}
              alt="ham_Menu"
            />
          </button>
          <ul className="nav_list" ref={navLinksRef}>
            <li>
              <NavLink
                to={"usecases"}
                className="nav_link "
                activeclassname={"active"}
              >
                Use Cases
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"enterprises"}
                className="nav_link"
                activeclassname={"active"}
              >
                My Enterprises
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"invite"}
                className="nav_link"
                activeclassname={"active"}
              >
                Invite your Team
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"Billing"}
                className="nav_link"
                activeclassname={"active"}
              >
                Billing
              </NavLink>
            </li>
            <li>
              <a href="#" className="nav_link">
                Documentation
              </a>
            </li>
            <li className="hider">
              <a href="#" className="nav_link " activeclassname={"active"}>
                Profile
              </a>
            </li>
            <li>
              <NavLink
                to={"setting"}
                className="nav_link"
                activeclassname={"active"}
              >
                Settings
              </NavLink>
            </li>
            <li className="hider">
              <a href="/#" className="nav_link " activeclassname={"active"}>
                Logout
              </a>
            </li>
          </ul>
          <ul className="nav_other_options">
            <li>
              <button className="bookMark_btn">
                <img
                  className="filterDark"
                  src={require("../../assets/images/Book_Mark.png")}
                  alt="Bookmark"
                />
              </button>
            </li>
            <li>
              <button
                className="screenTheme_btn"
                onClick={() => {
                  dispatch(setPiTheme(theme === "dark" ? "Light" : "dark"));
                  document.documentElement.setAttribute("data-theme", theme === "dark" ? "Light" : "dark");
                }}
              >
                <img
                  src={require(`../../assets/images/${
                    theme === "dark" ? "Light" : "dark"
                  }.png`)}
                  alt="Theme"
                />
              </button>
            </li>
            <li>
              <button className="profile_btn">
                <span>John Smith</span>
                <img
                  src={require("../../assets/images/Profile_Img.png")}
                  alt="Profile"
                />
              </button>
            </li>
            <li>
              <button className="logout_btn">
                <Link to="">
                  <img
                    className="filterDark"
                    src={require("../../assets/images/logout.png")}
                    alt="Logout"
                  />
                </Link>
              </button>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
export default PiNav;
