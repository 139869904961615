import { useContext } from "react";
import OwnModalContext from "pi-screen/own_modal/context/ownModalContext";
import classes from "./OwnModalRadioList.module.css";
const OwnModalRadioList = ({
  name,
  id,
  value,
  handler,
  question,
  contentData,
}) => {
  const ctx = useContext(OwnModalContext);
  const inputOnChangeHandler = (e) => {
    handler(question, e.target.value);
  };

  let checked = "";
  const existingCompanyModalIndex = ctx.formDetail.findIndex(
    (modal) => modal.question === question
  );
  const existingCompanyModal = ctx.formDetail[existingCompanyModalIndex];
  if (existingCompanyModal) {
    checked = existingCompanyModal.name;
  }

  return (
    <li className={classes.radio_optionItem}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked === value}
        onChange={inputOnChangeHandler}
      />
      <label htmlFor={id}>
        <img
          src={
            checked === value
              ? contentData.activeImageUrl
              : contentData.inActiveImageUrl
          }
          alt={contentData.questionType}
        />
        <span>{value}</span>
      </label>
    </li>
  );
};

export default OwnModalRadioList;
