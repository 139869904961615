
import DataSourceInput from './DatasourceDbInput/DatasourceDbInput';
import DataSourceDbChips from './DatasoucreDBChips/DatasourceDbChips';
import classes from './OwnModalTableRows.module.css';
const OwnModalTableRow = ({ data, inputChanger, questionType, value, isError }) => {

  const formValueSetter = (value) => {
    inputChanger(questionType, value)
  }

  return (
    <tr className={classes.tableRows}>
      <td>
        <div className={`${classes.td_Wrapper} ${classes.subQuestion_Wrapper}`}>
          <p>{data.question}<sup>*</sup></p>
        </div>
      </td>
      <td>
        <div className={`${classes.td_Wrapper} ${classes.subAnswer_Wrapper}`}>
          {(data.outputType === "text" || data.outputType === "password") && <DataSourceInput type={data.outputType} formInputChanger={formValueSetter} value={value} questionType={questionType} isError={isError} />}

          {data.outputType === "radio" && <DataSourceDbChips type={data.outputType} options={data.options} formInputChanger={formValueSetter} value={value} />}
        </div>
      </td>
    </tr >
  )
}

export default OwnModalTableRow;