import classes from './OwnModalSubmitBtn.module.css';
const OwnModalSubmitBtn = ({ name, extraClass, handler }) => {
  return (
    <button type="submit" onClick={handler} className={`${classes.submitBtn} ${extraClass !== null ? classes[extraClass] : undefined}`}>
      {name}
      <img className='filterDark'
        src={require("../../../assets/images/arrow_icon_gradient.png")}
        alt=""
      />
    </button>
  )
}

export default OwnModalSubmitBtn;

