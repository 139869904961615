import classes from './DatasourceDbChips.module.css';

const DataSourceDbChips = ({ options, type, value, formInputChanger }) => {
  const inputChangeHandler = (e) => {
    formInputChanger(e.target.value, () => { })
  }
  return (
    <ul className={classes.chipList}>
      {
        options.map((opt) => {
          return (
            <li className={classes.chips} key={opt}>
              <input type={type} id={`dataSource${opt}`} name='DataSoruce' value={opt} checked={opt === value ? true : false} onChange={inputChangeHandler} />
              <label htmlFor={`dataSource${opt}`} className={classes.customLabel}>
                {opt}
              </label>
            </li>
          )
        })
      }
    </ul>
  )
}

export default DataSourceDbChips;