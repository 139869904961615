
import classes from './DatasourceDbInput.module.css';
const DataSourceInput = ({ questionType, type, formInputChanger, value, isError }) => {
  const inputChanger = (e) => {

    formInputChanger(e.target.value.trim())
  }

  let classForInput = `${classes.modalTextInput}`;
  if (isError) {
    if (value.trim() === "") {
      classForInput = `${classes.modalTextInput} ${classes.error}`;
    }
  }


  return (
    <input type={type} className={classForInput} onChange={inputChanger} value={value} />
  )

}


export default DataSourceInput;