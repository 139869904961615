import React, { useRef } from "react";
import classes from './FileUploader.module.css';
const FileUploader = ({ handler, deleteHandler, uploadedFiles }) => {
  const fileInput = useRef();
  const handleMultipleFiles = (value) => {
    const modVal = value.map((item) => ({
      id: Number(new Date()) + Number(item.size) + Math.random() * 1000,
      data: item.name,
      size: formatFileSize(item.size),
    }));
    fileInput.current.value = '';
    handler(modVal);
  };
  const formatFileSize = (size) => {
    const kb = 1024;
    const mb = kb * 1024;
    // console.log(size);
    if (size >= mb) {
      return (size / mb).toFixed(2) + " MB";
    } else if (size >= kb) {
      return (size / kb).toFixed(2) + " KB";
    } else {
      return size + " bytes";
    }
  };
  const fileDelete = (id) => {
    deleteHandler(id)
  }
  return (
    <div className={classes.qa_fileDrop_area}>
      <div className={classes["file-uploadArea"]}>
        <label className={classes["custom-label"]} htmlFor="file-input">
          <div className="uploadImg">
            <img
              src={require("assets/images/upload icon.png")}
              alt="Upload Here"
            />
          </div>
          <h4>Drop Your Files Here</h4>
          <div className={classes["subBtn"]}>
            <p>Upload</p>
          </div>
          <input
            style={{ display: " none" }}
            ref={fileInput}
            type="file"
            id="file-input"
            multiple
            onChange={(e) => handleMultipleFiles([...e.target.files])}
          />
        </label>
        <ul className={classes["file-list"]}>
          {
            uploadedFiles.map((item) => (
              <li className={classes["file-item"]} key={item.id}>
                <span className={classes["file-itemimg"]}>
                  <img src={require("assets/images/jpg icon.png")} alt="" />
                </span>
                <span className={classes["file-name"]}>
                  <h6>{item.data}</h6>
                  <p>{item.size}</p>
                </span>
                <span
                  className={classes["file-delete"]}
                  onClick={() =>
                    fileDelete(item.id)
                  }
                >
                  <img
                    src={require("assets/images/close icon.png")}
                    alt="delete"
                  />
                </span>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default FileUploader;