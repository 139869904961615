import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const TextToImagePdf = () => {
  const [resultView, setResultView] = useState(false);
  const [type, setType] = useState("Image");
  const navigate = useNavigate();

  return (
    <>
      <div className="use_case_ex_container p-0">
        <div className="text_to_image_container">
          <div className="input_container">
            <div className="form-group form_group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter the text to convert text to Image /PDF"
                autoFocus
              />
            </div>
          </div>
          <div className="text_to_image_selectionArea">
            <div className="text_to_image_tabHeadArea">
              <label
                className="text_to_image_tabHead active"
                htmlFor="try_presets"
              >
                <input
                  type="radio"
                  checked={type === "Image" ? true : false}
                  id="try_presets"
                  name="text_to_image_tabHead"
                  value="image"
                  onChange={(e) => {
                    if (resultView === true) setResultView(false);

                    e.target.checked ? setType("Image") : setType("Pdf");
                  }}
                />
                <span>Image</span>
              </label>
              <label className="text_to_image_tabHead" htmlFor="try_own_data">
                <input
                  type="radio"
                  id="try_own_data"
                  checked={type === "Pdf" ? true : false}
                  name="text_to_image_tabHead"
                  value="pdf"
                  onChange={(e) => {
                    if (resultView === true) setResultView(false);

                    e.target.checked ? setType("Pdf") : setType("Image");
                  }}
                />
                <span>PDF</span>
              </label>
            </div>
          </div>
          <div className="generate_btn_container">
            <button
              className="half_border_button"
              onClick={() => setResultView(true)}
            >
              Generate
            </button>
          </div>
          {resultView ? (
            <div className="image_preview_container ">
              <div className="image_preview_header">
                <h3>{type}</h3>
                <button
                  className="closed"
                  onClick={() => setResultView(!resultView)}
                >
                  <img className="filterDark"
                    src={require("../../../../assets/images/close_icon_black.png")}
                    alt="close_icon_black"
                  />
                </button>
              </div>
              <div className="image_preview_body">
                <h5>Text</h5>
                <h2>Artificial Inteligence</h2>
                {type === "Image" && (
                  <div className="choose_font_color_picker">
                    <div className="choose_font_Container">
                      <h4>Choose Font</h4>
                      <select name="fontFamily" id="fontFamily">
                        <option value="fontBold">Manrope</option>
                        <option value="serif">serif</option>
                        <option value="'Times New Roman', Times, serif">
                          Times New Roman
                        </option>
                      </select>
                    </div>
                    <label className="color_picker_container">
                      <input type="color" id="color_picker" />
                      <img
                        src={require("../../../../assets/images/color_wheel.png")}
                        alt=""
                      />
                      <h4>Color Picker</h4>
                    </label>
                  </div>
                )}
                {type === "Image" ? (
                  <div className="preview_images_holder">
                    <div className="img_holder">
                      <img
                        src={require("../../../../assets/images/img_1.png")}
                        alt="img_11"
                      />
                      <h3>Artificial Inteligence</h3>
                      <button className="download">
                        <img
                          src={require("../../../../assets/images/download_icon.png")}
                          alt="download_icon"
                        />
                      </button>
                    </div>
                    <div className="img_holder">
                      <img
                        src={require("../../../../assets/images/img_2.png")}
                        alt="img_2"
                      />
                      <h3>Artificial Inteligence</h3>
                      <button className="download">
                        <img
                          src={require("../../../../assets/images/download_icon.png")}
                          alt="download_icon"
                        />
                      </button>
                    </div>
                    <div className="img_holder">
                      <img
                        src={require("../../../../assets/images/img_5.png")}
                        alt="img_3"
                      />
                      <h3>Artificial Inteligence</h3>
                      <button className="download">
                        <img
                          src={require("../../../../assets/images/download_icon.png")}
                          alt="download_icon"
                        />
                      </button>
                    </div>
                    <div className="img_holder">
                      <img
                        src={require("../../../../assets/images/img_4.png")}
                        alt="img_4"
                      />
                      <h3>Artificial Inteligence</h3>
                      <button className="download">
                        <img
                          src={require("../../../../assets/images/download_icon.png")}
                          alt="download_icon"
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="preview__pdf_holder">
                    <div className="img_holder">
                      <img
                        src={require("../../../../assets/images/PDF_icon.png")}
                        alt="img_11"
                      />
                      <button className="download">
                        <img
                          src={require("../../../../assets/images/download_icon.png")}
                          alt="download_icon"
                        />
                      </button>
                    </div>
                    <div className="img_holder">
                      <img
                        src={require("../../../../assets/images/PDF_icon.png")}
                        alt="img_11"
                      />
                      <button className="download">
                        <img
                          src={require("../../../../assets/images/download_icon.png")}
                          alt="download_icon"
                        />
                      </button>
                    </div>
                    <div className="img_holder">
                      <img
                        src={require("../../../../assets/images/PDF_icon.png")}
                        alt="img_11"
                      />
                      <button className="download">
                        <img
                          src={require("../../../../assets/images/download_icon.png")}
                          alt="download_icon"
                        />
                      </button>
                    </div>
                    <div className="img_holder">
                      <img
                        src={require("../../../../assets/images/PDF_icon.png")}
                        alt="img_11"
                      />
                      <button className="download">
                        <img
                          src={require("../../../../assets/images/download_icon.png")}
                          alt="download_icon"
                        />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TextToImagePdf;
