import React from 'react'

const Enabiling = () => {
  return (
    <section id="enabling_navigate">
    <div className="how_we_enabling_Container">
      <div className="home_container_header">
        <h2>How we are enabling</h2>
        <p>Emerging LLM App Stack</p>
      </div>
      <div className="how_we_enabling_img_holder">

        <video src={require("../../../assets/images/PiProjections_Enabling.mp4")} autoPlay muted loop plays-inline={"true"} />

      </div>
    </div>
  </section>
  )
}

export default Enabiling