import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

const UseCaseGlobalCardScreen = () => {
  const { data } = useParams();
  const [category, SetCategory] = useState("");
  const Location = useLocation();
  const navigate = useNavigate();

  const cardsOptions = {
    "Agent Simulation": [
      {
        img: "Home_AgentSimulation_Black_1",
        active: "Home_AgentSimulation_Blue_1",
        name: "Agent",
        details:
          " Creates artificial agents that interact with a simulated environment and are programmed to perform tasks or make decisions based on certain rules or learning algorithms",
      },
    ],
    "Chatbots & Personal Assistant": [
      {
        img: "Home_Chatbots&PersonalAssistant_Black_1",
        active: "Home_Chatbots&PersonalAssistant_Blue_1",
        name: "Chatbots",
        details:
          " AI systems designed to simulate human-like conversation, often used in customer service to answer common queries, guide users through processes, or provide information about products and services",
      },
      {
        img: "Home_Chatbots&PersonalAssistant_Black_2",
        active: "Home_Chatbots&PersonalAssistant_Blue_2",
        name: "Personal Assistant",
        details:
          "Also known as virtual assistants or intelligent personal assistants, that performs a wide range of tasks based on  voice commands",
      },
    ],
    "Autonomous Agents": [
      {
        img: "Home_AgentSimulation_Black_1",
        active: "Home_AgentSimulation_Blue_1",
        name: "Autonomous Agents",
        details:
          " Operates independently, make decisions, and perform tasks without the need for continuous human guidance",
      },
    ],
    "Querying Tabular Data": [
      {
        img: "Home_QA_Black_2",
        active: "Home_QA_Blue_2",
        name: "Querying Tabular Data",
        details:
          " Reduces a larger piece of content into its key points or essence while retaining its core meaning",
      },
    ],
    "API Integration": [
      {
        img: "Home_APIIntegrationt_Black_1",
        active: "Home_APIIntegrationt_Blue_1",
        name: "Machine Learning APIs",
        details:
          " Predefined machine learning APIs can be used to train own  models using the platform's resources, or pre-trained   models can be used directly that are provided by the  platform.",
      },
      {
        img: "Home_APIIntegrationt_Black_2",
        active: "Home_APIIntegrationt_Blue_2",
        name: "Natural Language Processing APIs",
        details:
          " Provides access to natural language processing services,  which can analyze text for sentiment, extract key phrases,  classNameify text into categories, translate text between   languages, and more",
      },
      {
        img: "Home_APIIntegrationt_Black_3",
        active: "Home_APIIntegrationt_Blue_3",
        name: "Speech Recognition APIs",
        details:
          "  These APIs can convert spoken language into written text,  useful for transcribing audio, enabling voice control in  applications, and more",
      },
      {
        img: "Home_APIIntegrationt_Black_4",
        active: "Home_APIIntegrationt_Blue_4",
        name: "Image & Video Recognition APIs",
        details:
          " These APIs can analyze images or videos to identify objects, people, text, or actions",
      },
      {
        img: "Home_APIIntegrationt_Black_5",
        active: "Home_APIIntegrationt_Blue_5",
        name: "Chatbot APIs",
        details:
          " These APIs can be used to integrate the chatbot with their own applications or with third-party services",
      },
    ],
  };
  return (
    <>
      <div className="sub_nav ad_content">
        <div className="sub_nav_left_Content">
          <div className="breadcrums">
            <Link to="/usecases">{"Usecases"}</Link>
            {" > "}
            <a href="#"> {data}</a>
          </div>
          <h3 className="use_case_detailer">
            {/* {navOptions?.[URLpath_lastName]?.img && (
              <img
                className="filterDark"
                src={require(`assets/images/${navOptions?.[URLpath_lastName]?.img}.png`)}
                alt="QA_black_icon"
              />
            )} */}

            {data}
          </h3>
        </div>
        <div className="sub_nav_right_Content">
          <p>Create Your Own Model</p>
          <div className="btn_container">
            <button
              className="half_border_button"
              type="button"
              aria-expanded="false"
              onClick={() => navigate("/ownModel")}
              disabled={Location.pathname === "/ownModel" ? true : false}
            >
              <img
                className="icon filterDark"
                src={require("../../../../assets/images/q_a_icon.png")}
                alt=""
              />
              <span>Q&A Based</span>
              <img
                className="arrow filterDark"
                src={require("../../../../assets/images/arrow_icon_gradient.png")}
                alt=""
              />
            </button>
            <button
              className="half_border_button"
              type="button"
              aria-expanded="false"
              onClick={() => navigate("/uiDriven")}
              disabled={Location.pathname === "/uiDriven" ? true : false}
            >
              <img
                className="icon filterDark"
                src={require("../../../../assets/images/UI_icon.png")}
                alt=""
              />
              <span>UI Driven</span>
              <img
                className="arrow filterDark"
                src={require("../../../../assets/images/arrow_icon_gradient.png")}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <div className="use_case_ex_container">
        <div className="qa_docs_container">
          <div className="qa_docs_selectionArea">
            <div className="qa_docs_tabbodyArea">
              <div className="qa_docs_tabbody ">
                <div className="qa_docs_cards">
                  {cardsOptions[data]?.map((item, i) => (
                    <div
                      key={i}
                      className={`qa_docs_card ${
                        category === item.name ? "active" : ""
                      }`}
                      onClick={() => SetCategory(item.name)}
                    >
                      <div className="cardImg">
                        <img
                          className={`dummy  ${
                            category === item.name ? "hide" : ""
                          }`}
                          src={require(`assets/images/${item.img}.png`)}
                          alt="Icon"
                        />
                        <img
                          className={`dummy  ${
                            category === item.name ? "" : "hide"
                          }`}
                          src={require(`assets/images/${item.active}.png`)}
                          alt="Icon"
                        />
                      </div>
                      <div className="card_body">
                        <p className="qa_docs_card_name">{item.name}</p>
                        <p className="qa_docs_card_desc">{item.details}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="qa_docs_generate">
                  <button className="half_border_button">Generate</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseCaseGlobalCardScreen;
