import ChatAreaType1 from "component/chatarea";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import EnterprisesUSeCasesModal from "../enterprises_usecases_modal";

const Enterprises_UseCases_ChatArea = () => {
  const StoreData = useSelector((data) => data.enterpriseUsecasModalReducer);
  const navigate = useNavigate();
  return (
    <>
      <div className="sub_nav ad_content">
        <div className="sub_nav_left_Content">
          <div className="breadcrums">
            <Link to="/usecases">Enterprises Usecases</Link>
            {">"}
            <Link to="/usecases/EnterprisesUsecases">Multi Models</Link> {">"}
            <a href="#">{StoreData.modalName}</a>
          </div>
          <div className="local_storage_details">
            <h3 className="use_case_detailer">
              <img
                src={require("../../../../assets/images/sql_.png")}
                alt="sql_"
              />
              {StoreData.modalName}
            </h3>
            <button
              className="info"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <img
                src={require("../../../../assets/images/info_icon.png")}
                alt=""
              />
            </button>
          </div>
        </div>

        <div className="sub_nav_right_Content">
          <p>Create Your Own Model</p>

          <div className="btn_container">
            <button
              className="half_border_button"
              type="button"
              aria-expanded="false"
              onClick={() => navigate("/ownModel")}
            >
              <img
                className="icon"
                src={require("../../../../assets/images/q_a_icon.png")}
                alt=""
              />
              <span>Q&A Based</span>
              <img
                className="arrow"
                src={require("../../../../assets/images/arrow_icon_gradient.png")}
                alt=""
              />
            </button>
            <button
              className="half_border_button"
              type="button"
              aria-expanded="false"
              onClick={() => navigate("/uiDriven")}
            >
              <img
                className="icon"
                src={require("../../../../assets/images/UI_icon.png")}
                alt=""
              />
              <span>UI Driven</span>
              <img
                className="arrow"
                src={require("../../../../assets/images/arrow_icon_gradient.png")}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <div className="use_case_ex_container">
        <div className="qa_docs_container">
          <ChatAreaType1
            // setVisibilityTab={setVisibilityTab}
            // setTabActive={setTabActive}
            // tabactive={tabactive}
            // category={category}
            // documentName={documentName}
            chatType={"Summarization"}
            // tabHeads={["Test with your data", "Test out Presets"]}
          />
        </div>
      </div>
      <EnterprisesUSeCasesModal type={"StoreData"} />
      {/* <section>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered enterprises_usecase_modal">
            <div className="modal-content enterprises_usecase_modal_content">
              <div className="modal-header enterprises_usecase_modal_header">
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src={require("../../../../assets/images/close icon black.png")}
                    alt=""
                  />
                </button>
              </div>
              <div className="modal-body enterprises_usecase_modal_body">
                <div className="table_hoder">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Source</th>
                        <th>Url Links</th>
                      </tr>
                    </thead>
                    <tbody>
                      {StoreData.modalValue.map((item) => (
                        <tr>
                          <td className="data_name">{item.Source}</td>
                          <td>
                            <div className="modal_chip_container">
                              {item.url.map((it, i) => (
                                <div className="chip" key={it.id}>
                                  <p>{it.name}</p>
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Enterprises_UseCases_ChatArea;
