import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Register = () => {
  const navigate = useNavigate();
  const [EmailType, setEmailType] = useState("password");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Register",
    });
  }, []);
  return (
    <section className="home_res_log">
      <div className="register_container">
        <div className="register_content_container">
          <div className="register_header">
            <h3>Create your account</h3>
            <p>Accelerate AI for your enterprise</p>
          </div>
          <div className="register_Form">
            <form action="" id="register_Form">
              <div className="form-group form_group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                />
                <small></small>
              </div>
              <div className="form-group form_group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                />
                <small></small>
              </div>
              <div className="form-group form_group">
                <input
                  type="number"
                  className="form-control mobile_number"
                  placeholder="Mobile Number"
                />
                <small></small>
              </div>
              <div className="form-group form_group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                />
                <small></small>
              </div>

              <div className="form-group form_group">
                <input
                  type={EmailType}
                  className="form-control"
                  placeholder="Password"
                />
                <small></small>
                <button
                  className="password_type"
                  type="button"
                  onClick={() => {
                    EmailType === "password"
                      ? setEmailType("text")
                      : setEmailType("password");
                  }}
                >
                  <img
                    src={require(`../../assets/images/type_${EmailType}.png`)}
                    alt="eye_icon"
                  />
                </button>
              </div>

              <div className="submit">
                <button
                  type="button"
                  className="half_border_button"
                  onClick={() => navigate("/usecases")}
                >
                  Continue
                </button>
              </div>
            </form>
            <div className="login_with">
              <p>Or Sign In With</p>
            </div>
            <div className="oAuth_container">
              <div className="auth_logo">
                <img
                  src={require("../../assets/images/google_icon.png")}
                  alt="google_icon"
                />
              </div>
              <div className="auth_logo">
                <img
                  src={require("../../assets/images/microsoft_icon.png")}
                  alt="microsoft_icon"
                />
              </div>
              <div className="auth_logo">
                <img
                  src={require("../../assets/images/apple_icon.png")}
                  alt="apple_icon"
                />
              </div>
              <div className="auth_logo">
                <img
                  src={require("../../assets/images/github_icon.png")}
                  alt="apple_icon"
                />
              </div>
            </div>
            <div className="sign_up">
              <p>
                Already have a PiProjections account?&nbsp;
                <Link to="/Login">Sign In Now</Link>
              </p>
            </div>
          </div>
        </div>
        <div className="res_log_footer">
          <div className="footer_left_content">
            <p>Copyright &copy; 2023 PiProjections. All Rights Reserved</p>
          </div>
          <div className="footer_right_content">
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
