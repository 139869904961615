import React, { useState } from "react";

const Billing = () => {
  const [isBasicActive, setIsBasicActive] = useState(true);
  const [isEnterpriceActive, setIsEnterpriceActive] = useState(false);
  const tabBtnHandler = (e) => {
    if (e.target.textContent === "Basic") {
      setIsBasicActive(true);
      setIsEnterpriceActive(false);
    } else {
      setIsBasicActive(false);
      setIsEnterpriceActive(true);
    }
  };
  return (
    <>
      <div className="sub_nav ad_content settingScreen_nav">
        <div className="sub_nav_left_Content">
          <h3 className="use_case_detailer">
            <img className="filterDark"
              src={require("../../assets/images/Billing_Icon1.png")}
              alt="QA_black_icon"
            />
            Billing
          </h3>
        </div>
      </div>
      <div className="settingsContent">
        <div className="billingArea">
          <div className="subscription_tab_container">
            <ul
              className="nav nav-tabs tab_btn_container anim_top_botom"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item tab_btn" role="presentation">
                <button
                  className={`nav-link ${isBasicActive ? "active" : ""}`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={tabBtnHandler}
                >
                  Basic
                </button>
              </li>
              <li className="nav-item tab_btn" role="presentation">
                <button
                  className={`nav-link ${isEnterpriceActive ? "active" : ""}`}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  onClick={tabBtnHandler}
                >
                  Enterprises
                </button>
              </li>
              <li className={`slider_Anim ${isBasicActive ? 'left' : 'right'}`} role="presentation"></li>

            </ul>
            <div
              className="tab-content tab_content_container anim_top_botom"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="subscription_card_container">
                  <div className="subscription_card">
                    <div className="tag">
                      <div className="tag_content">
                        <h5>Trial</h5>
                      </div>
                    </div>
                    <div className="card_content">
                      <h2>Free</h2>
                      <h3>1 Text Repo</h3>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <button>Get Started</button>
                    </div>
                  </div>
                  <div className="subscription_card premium">
                    <div className="tag">
                      <div className="tag_content">
                        <h5>Pro</h5>
                      </div>
                    </div>
                    <div className="card_content">
                      <h2>
                        $ 12.99 <span>/Mo</span>
                      </h2>
                      <h3>Unlimited Repo</h3>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <button>Upgrade Now</button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="subscription_card_container">
                  <div className="subscription_card">
                    <div className="tag">
                      <div className="tag_content">
                        <h5>Trial</h5>
                      </div>
                    </div>
                    <div className="card_content">
                      <h2>Free</h2>
                      <h3>1 Text Repo</h3>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <button>Get Started</button>
                    </div>
                  </div>
                  <div className="subscription_card">
                    <div className="tag">
                      <div className="tag_content">
                        <h5>Pro</h5>
                      </div>
                    </div>
                    <div className="card_content">
                      <h2>Free</h2>
                      <h3>1 Text Repo</h3>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <button>Get Started</button>
                    </div>
                  </div>
                  <div className="subscription_card premium">
                    <div className="tag">
                      <div className="tag_content">
                        <h5>Premium</h5>
                      </div>
                    </div>
                    <div className="card_content">
                      <h2>
                        $ 12.99 <span>/Mo</span>
                      </h2>
                      <h3>Unlimited Repo</h3>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <p>
                        <img
                          src={require("../../assets/images/Grey.png")}
                          alt="tick"
                        />
                        <span>Lorem ipsum dolor sit amet.</span>
                      </p>
                      <button>Upgrade Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
