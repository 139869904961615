import ChatAreaType1 from "component/chatarea";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const Video = () => {
  const navigate = useNavigate();
  const [tabactive, setTabActive] = useState("Summarize");
  const [documentName, setDocunemtName] = useState("Summarize");
  const [visibilityTab, setVisibilityTab] = useState(true);

  const [category, SetCategory] = useState("Summarize");

  useEffect(() => {
    tabactive === "Summarize"
      ? setDocunemtName("Q/A")
      : setDocunemtName("Summarize");
  }, [tabactive]);
  useEffect(() => {
    SetCategory(tabactive);
  }, [tabactive]);

  return (
    <>
      <div className="use_case_ex_container">
        <div className="qa_docs_container">
          {visibilityTab ? (
            <div className="qa_docs_selectionArea">
              <div className="input_container">
                <div className="form-group form_group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your link here"  autoFocus
                  />
                  
                </div>
              </div>

              <div className="qa_docs_tabHeadArea">
                <label className="qa_docs_tabHead active" htmlFor="try_presets">
                  <input
                    type="radio"
                    checked={tabactive === "Summarize" ? true : false}
                    id="try_presets"
                    name="qa_docs_tabHead"
                    onChange={(e) => {
                      e.target.checked
                        ? setTabActive("Summarize")
                        : setTabActive("Q/A");
                    }}
                  />
                  <span>Summarize</span>
                </label>
                <label className="qa_docs_tabHead" htmlFor="try_own_data">
                  <input
                    type="radio"
                    id="try_own_data"
                    checked={tabactive === "Q/A" ? true : false}
                    name="qa_docs_tabHead"
                    onChange={(e) => {
                      e.target.checked
                        ? setTabActive("Q/A")
                        : setTabActive("Summarize");
                    }}
                  />
                  <span>Q/A</span>
                </label>
              </div>
              <div className="qa_docs_tabbodyArea">
                <div className="qa_docs_tabbody active">
                  <div className="qa_docs_generate">
                    <button
                      className="half_border_button"
                      onClick={() => setVisibilityTab(false)}
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {tabactive === "Summarize" ? (
                <ChatAreaType1
                  setVisibilityTab={setVisibilityTab}
                  setTabActive={setTabActive}
                  tabactive={tabactive}
                  category={category}
                  documentName={documentName}
                  chatType={"QA"}
                  tabHeads={["Summarize", "Q/A"]}
                />
              ) : (
                <ChatAreaType1
                  setVisibilityTab={setVisibilityTab}
                  setTabActive={setTabActive}
                  tabactive={tabactive}
                  category={category}
                  documentName={documentName}
                  chatType={"Summarization"}
                  tabHeads={["Summarize", "Q/A"]}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Video;
