import { useContext, useRef, useState, useEffect } from "react";
import OwnModalContext from "pi-screen/own_modal/context/ownModalContext";
import OwnModalFoundational from "./OwnModalFoundational/OwnModalFoundational";
import OwnModalList from "./OwnModalList/OwnModalList";
import OwnModalRadioList from "./OwnModalRadioList/OwnModalRadioList";
import DatasourceDataBase from "./DatasourceDataBaseModal/DatasourceDataBase";
import StoreDataOtherModal from "./StoreDataOtherModal/StoreDataOtherModal";
import OwnModalModal from "pi-screen/own_modal/Modal/OwnModalModal";
import classes from "./OwnModalListItem.module.css";
const OwnModalListItem = ({ contentData }) => {
  const ctx = useContext(OwnModalContext);
  // const clicked
  const textInput = useRef();

  useEffect(() => {
    if (textInput.current) {
      console.log("shif");
      textInput.current.focus();
    }
  }, [contentData]);

  const [modalType, setModalType] = useState(null);
  const companyNameHandler = () => {
    ctx.companyNameHandler(contentData.question, textInput.current.value);
  };
  const normalOptionHandler = (question, option) => {
    ctx.normalOptionHandler(question, option);
  };

  const dataSourceHandler = (question, dataName) => {
    if (dataName === "Database") {
      const existingModalIndex = ctx.formDetail.findIndex(
        (modal) => modal.question === question
      );
      const existingModal = ctx.formDetail[existingModalIndex];
      console.log(existingModal);
      if (existingModal) {
        const existingOptions = existingModal.option.map((opt) => opt.name);
        console.log(existingOptions);
        if (existingOptions.includes(dataName)) {
          ctx.dataSourceHandler(question, dataName);
        } else {
          setModalType(dataName);
        }
      } else {
        setModalType(dataName);
      }
    } else {
      ctx.dataSourceHandler(question, dataName);
    }
  };
  const dataSourceDatabaseModalHandler = (question, subOptions, dataname) => {
    setModalType(null);
    ctx.dataSourceHandler(question, { name: dataname, subOptions: subOptions });
  };

  const modalcloser = () => {
    setModalType(null);
  };

  const storeDataHandler = (question, dataName) => {
    if (dataName === "Others") {
      const existingModalIndex = ctx.formDetail.findIndex(
        (modal) => modal.question === question
      );
      const existingModal = ctx.formDetail[existingModalIndex];
      console.log(existingModal);
      if (existingModal) {
        const existingOptions = existingModal.option.map((opt) => opt.name);
        console.log(existingOptions);
        if (existingOptions.includes(dataName)) {
          ctx.dataSourceHandler(question, dataName);
        } else {
          setModalType(dataName);
        }
      } else {
        setModalType(dataName);
      }
    } else {
      ctx.dataSourceHandler(question, dataName);
    }
  };

  const radioHandler = (question, value) => {
    ctx.companyNameHandler(question, value);
  };

  let output;
  if (contentData.questionType === "Company Name") {
    let value = "";
    const existingCompanyModalIndex = ctx.formDetail.findIndex(
      (modal) => modal.question === contentData.question
    );
    const existingCompanyModal = ctx.formDetail[existingCompanyModalIndex];
    if (existingCompanyModal) {
      value = existingCompanyModal.name;
    }
    let defaultClasses = `${classes.company_Name}`;
    if (!existingCompanyModal) {
      if (value === "") {
        if (textInput.current) {
          // console.log('shif');
          defaultClasses += ` ${classes.error}`;
          // textInput.current.focus()
        }
      }
    }

    output = (
      <input
        type="text"
        className={defaultClasses}
        id="company_Name"
        ref={textInput}
        value={value}
        onChange={companyNameHandler}
      />
    );
  }
  if (
    contentData.questionType === "Industries" ||
    contentData.questionType === "Business" ||
    contentData.questionType === "Cloud platforms"
  ) {
    output = (
      <ul className={classes.modal_QA_List_Options}>
        {contentData.options.map((data) => {
          return (
            <OwnModalList
              data={data}
              questionType={contentData.questionType}
              question={contentData.question}
              key={data.name}
              handler={normalOptionHandler}
            />
          );
        })}
      </ul>
    );
  }
  if (contentData.questionType === "Data Source") {
    output = (
      <ul className={classes.modal_QA_List_Options}>
        {modalType === "Database" && (
          <OwnModalModal>
            <DatasourceDataBase
              modal={modalType}
              question={contentData.question}
              closeHandler={modalcloser}
              submitHandler={dataSourceDatabaseModalHandler}
            />
          </OwnModalModal>
        )}
        {contentData.options.map((data) => {
          return (
            <OwnModalList
              data={data}
              questionType={contentData.questionType}
              question={contentData.question}
              key={data.name}
              handler={dataSourceHandler}
            />
          );
        })}
      </ul>
    );
  }
  if (contentData.questionType === "Foundational Model") {
    output = (
      <OwnModalFoundational
        data={contentData}
        question={contentData.question}
        activetabListItem="LLM"
      />
    );
  }
  if (contentData.questionType === "Data Vector Store") {
    output = (
      <OwnModalFoundational
        data={contentData}
        question={contentData.question}
        activetabListItem="OpenSource"
      />
    );
  }

  if (
    contentData.questionType === "Generative AI" ||
    contentData.questionType === "Caching" ||
    contentData.questionType === "Customize Prompt"
  ) {
    output = (
      <ul className={classes.radio_optonList}>
        {contentData.value.map((rad) => {
          return (
            <OwnModalRadioList
              question={contentData.question}
              contentData={contentData}
              key={`${contentData.questionType}${rad}`}
              name={contentData.questionType}
              id={`${contentData.questionType}${rad}`}
              value={rad}
              handler={radioHandler}
            />
          );
        })}
      </ul>
    );
  }

  if (contentData.questionType === "Store Data") {
    output = (
      <ul className={classes.modal_QA_List_Options}>
        {modalType === "Others" && (
          <OwnModalModal>
            <StoreDataOtherModal
              modal={modalType}
              question={contentData.question}
              closeHandler={modalcloser}
              submitHandler={dataSourceDatabaseModalHandler}
            />
          </OwnModalModal>
        )}
        {contentData.options.map((data) => {
          return (
            <OwnModalList
              data={data}
              questionType={contentData.questionType}
              question={contentData.question}
              key={data.name}
              handler={storeDataHandler}
            />
          );
        })}
      </ul>
    );
  }
  const isRadio =
    contentData.questionType === "Generative AI" ||
    contentData.questionType === "Caching" ||
    contentData.questionType === "Customize Prompt";
  return (
    <>
      <li className={classes.modal_QA_Content_item}>
        <p
          className={`${classes.modal_QA_Question} ${
            isRadio ? classes.radio : undefined
          }`}
        >
          <span>{contentData.question}</span>
          <span>*</span>
        </p>
        {output}
      </li>
    </>
  );
};

export default OwnModalListItem;
