import React, { useEffect, useRef, useState } from "react";

const ChatAreaType1 = ({
  setVisibilityTab,
  setTabActive,
  tabactive,
  category,
  documentName,
  chatType,
  tabHeads,
}) => {
  const [userQuestion, setUserQuestion] = useState("");
  const [emojiOpen, setEmojiOpen] = useState(null);
  const chatAreaRef = useRef();
  const [questionChips, SetQuestionChips] = useState([]);
  const [resultType, setResultType] = useState("bulletPoints");
  const [BulletType, setBulletType] = useState(0);
  const customQuestionChip = [
    "Which Party Did he Belong To?",
    // " What is The Square Root Of Banana?",
    " How Many Squigs Are in A Bonk?",
  ];
  useEffect(() => {
    if (chatAreaRef?.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  }, [questionChips]);
  useEffect(() => {}, []);

  return (
    <div className="qa_docs_chatArea  chatScrollparent">
      {tabHeads ? (
        <div className="topHeader">
          <p>
            <span id="headState"> Presets Name</span>
            <span id="headContent">{category} </span>
          </p>
          <a
            href="#"
            onClick={() => {
              tabactive === tabHeads[1]
                ? setTabActive(tabHeads[0])
                : setTabActive(tabHeads[1]);
              // setDocunemtName === tabHeads[1]
              //   ? setTabActive(tabHeads[0])
              //   : setTabActive(tabHeads[1]);
              setVisibilityTab(true);
            }}
          >
            <p id="stateToggler">{documentName}</p>
            <img src={require("assets/images/arrow.png")} alt="Try Now" />
          </a>
        </div>
      ) : (
        ""
      )}

      <div
        ref={chatAreaRef}
        id="chatScroll"
        className={`chatScroll ${chatType}`}
      >
        {questionChips.length > 0
          ? questionChips.map((item, i) => (
              <div className="chatDialogBox" key={item.id}>
                <div className="chatQuestion">
                  <a href="#">
                    <p>{item.data}</p>
                  </a>
                  <div className="chatQuestion_btns">
                    <div className="chatQuestion_btn">
                      <img
                        src={require("assets/images/select all icon.png")}
                        alt="select All"
                      />
                    </div>
                    <div className="chatQuestion_btn">
                      <img
                        src={require("assets/images/paste icon.png")}
                        alt="Copy"
                      />
                    </div>
                    <div className="chatQuestion_btn">
                      <img
                        src={require("assets/images/export icon.png")}
                        alt="Export"
                      />
                    </div>
                  </div>
                </div>
                <div className="chatAnswer">
                  {chatType === "Summarization" &&
                  item.status === "oneSentence" ? (
                    <p>
                      Lorem ipsu elit. Facere est eum in maiores, laborum unde
                      autem nostrum vero perferendis eos quidem aut nisi nobis,
                      labore, earum quos.
                    </p>
                  ) : item.status === "bulletPoints" ? (
                    <ul>
                      <li>
                        <span className="bullet">
                          <img
                            src={require(`../../assets/images/bullet library ${
                              item.bulletPointStatus + 1
                            }.png`)}
                            alt="bullet"
                          ></img>
                        </span>
                        <span className="bulletText">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Sint nobis deserunt nesciunt rerum magni,
                          quaerat impedit beatae, laborum delectus ab eaque nemo
                          at consectetur omnis, similique exercitationem odio
                          tempora amet!
                        </span>
                      </li>
                      <li>
                        <span className="bullet">
                          <img
                            src={require(`../../assets/images/bullet library ${
                              item.bulletPointStatus + 1
                            }.png`)}
                            alt="bullet"
                          ></img>
                        </span>
                        <span className="bulletText">
                          Sint nobis deserunt nesciunt rerum magni, quaerat
                          impedit beatae, laborum delectus ab eaque nemo at
                          consectetur omnis, similique exercitationem odio
                          tempora amet!
                        </span>
                      </li>
                      <li>
                        <span className="bullet">
                          <img
                            src={require(`../../assets/images/bullet library ${
                              item.bulletPointStatus + 1
                            }.png`)}
                            alt="bullet"
                          ></img>
                        </span>
                        <span className="bulletText">
                          Lorem ipsum dolor Lorem ipsum dolor sit amet
                          consectetur adipisicing elit. Recusandae, consequatur
                          sunt. Omnis rerum corporis praesentium fugit quibusdam
                          inventore optio libero facere nisi. Et nobis quas
                          accusamus id expedita ipsum dicta.quaerat impedit
                          beatae, laborum delectus ab eaque nemo at consectetur
                          omnis, similique exercitationem odio tempora amet!
                        </span>
                      </li>
                    </ul>
                  ) : item.status === "longFormat" ? (
                    <>
                      <p>
                        Lorem ipsu elit. Facere est eum in maiores, laborum unde
                        autem nostrum vero perferendis eos quidem aut nisi
                        nobis, labore, earum quos. Optio, odit ad! consequuntur
                        atque, accusantium adipisicing elit. Neque aspernatur
                        qui veritatis, praesentium itaque iusto molestiae
                        reiciendis, blanditiis quas iste iure repellendus?
                        Reprehenderit odit non nobis eveniet.
                      </p>
                      <p>
                        Lorem ipsu elit. Facere est eum in maiores, laborum unde
                        autem nostrum vero perferendis eos quidem aut nisi
                        nobis, labore, earum quos. Optio, odit ad! consequuntur
                        atque, accusantium adipisicing elit. Neque aspernatur
                        qui veritatis, praesentium itaque iusto molestiae
                        reiciendis, blanditiis quas iste iure repellendus?
                        Reprehenderit odit non nobis eveniet.
                      </p>
                      <p>
                        Lorem ipsu elit. Facere est eum in maiores, laborum unde
                        autem nostrum vero perferendis eos quidem aut nisi
                        nobis, labore, earum quos. Optio, odit ad! consequuntur
                        atque, accusantium adipisicing elit. Neque aspernatur
                        qui veritatis, praesentium itaque iusto molestiae
                        reiciendis, blanditiis quas iste iure repellendus?
                        Reprehenderit odit non nobis eveniet.
                      </p>
                    </>
                  ) : (
                    <p>
                      Lorem ipsu elit. Facere est eum in maiores, laborum unde
                      autem nostrum vero perferendis eos quidem aut nisi nobis,
                      labore, earum quos. Optio, odit ad! consequuntur atque,
                      accusantium adipisicing elit. Neque aspernatur qui
                      veritatis, praesentium itaque iusto molestiae reiciendis,
                      blanditiis quas iste iure repellendus? Reprehenderit odit
                      non nobis eveniet.
                    </p>
                  )}
                  {chatType === "QA" && (
                    <p>
                      Lorem ipsu elit. Facere est eum in maiores, laborum unde
                      autem nostrum vero perferendis eos quidem aut nisi nobis,
                      labore, earum quos.
                    </p>
                  )}
                </div>
                <div className="chatReaction_container">
                  <div className="reaction-button">
                    <div
                      className="emoji defaultEmoji"
                      onClick={() =>
                        item.id === emojiOpen
                          ? setEmojiOpen(null)
                          : setEmojiOpen(item.id)
                      }
                    >
                      <img
                        src={require(`assets/images/${
                          item.liekStatus === true
                            ? "thumbs up fill colour"
                            : item.liekStatus === false
                            ? "thumbs down fill colour"
                            : "smilie blue colour icon"
                        }.png`)}
                        alt="reaction"
                      />
                    </div>
                    <div
                      className={`emoji-container ${
                        item.id === emojiOpen ? "open" : ""
                      }`}
                    >
                      <span
                        className="emoji"
                        // onclick="selectReaction('like',${questionCount})"
                        onClick={() => {
                          const data = questionChips.map((items) =>
                            items.id === item.id
                              ? { ...items, liekStatus: true }
                              : items
                          );
                          // console.log(data);
                          SetQuestionChips([...data]);
                          setEmojiOpen(null);
                        }}
                      >
                        <img
                          src={require("assets/images/thumbs up grey icon.png")}
                          alt="reaction"
                        />
                      </span>
                      <span
                        className="emoji"
                        onClick={() => {
                          const data = questionChips.map((items) =>
                            items.id === item.id
                              ? { ...items, liekStatus: false }
                              : items
                          );
                          // console.log(data);
                          SetQuestionChips([...data]);
                          setEmojiOpen(null);
                        }}
                      >
                        <img
                          src={require("assets/images/thumbs down grey icon.png")}
                          alt="reaction"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : ""}
      </div>
      <div className="chatFooter">
        <ul className="footerChips">
          {customQuestionChip.map((item, i) => (
            <li
              key={i}
              className="questionChips"
              onClick={() => {
                chatType === "QA"
                  ? SetQuestionChips(() => [
                      ...questionChips,
                      {
                        id: Number(new Date()),
                        data: item,
                        liekStatus: null,
                      },
                    ])
                  : SetQuestionChips(() => [
                      ...questionChips,
                      {
                        id: Number(new Date()),
                        data: item,
                        liekStatus: null,
                        status: resultType,
                        bulletPointStatus:
                          resultType === "bulletPoints" ? BulletType : null,
                      },
                    ]);
              }}
            >
              {item}
            </li>
          ))}
        </ul>
        {chatType === "Summarization" && (
          <div className="formater_contaner">
            <div className="summarizeSelectArea">
              <div className="radioInputs">
                <label
                  className="summurizeRadioBtn active"
                  htmlFor="oneSentence"
                >
                  <input
                    type="radio"
                    id="oneSentence"
                    value="oneSentence"
                    //   onclick="formatChange(this)"
                    name="outputFormat"
                    checked={resultType === "oneSentence" ? true : false}
                    onChange={(e) => setResultType(e.target.value)}
                  />
                  <span>One Sentence</span>
                </label>
                <label
                  className="summurizeRadioBtn active"
                  htmlFor="bulletPoints"
                >
                  <input
                    type="radio"
                    id="bulletPoints"
                    value="bulletPoints"
                    //   onclick="formatChange(this)"
                    name="outputFormat"
                    checked={resultType === "bulletPoints" ? true : false}
                    onChange={(e) => setResultType(e.target.value)}
                  />
                  <span>Bullet Points</span>
                </label>
                <label className="summurizeRadioBtn" htmlFor="sortFormat">
                  <input
                    type="radio"
                    id="sortFormat"
                    value="shortFormat"
                    //   onclick="formatChange(this)"
                    name="outputFormat"
                    checked={resultType === "shortFormat" ? true : false}
                    onChange={(e) => setResultType(e.target.value)}
                  />
                  <span>Short</span>
                </label>
                <label className="summurizeRadioBtn" htmlFor="longFormat">
                  <input
                    type="radio"
                    id="longFormat"
                    value="longFormat"
                    //   onclick="formatChange(this)"
                    name="outputFormat"
                    checked={resultType === "longFormat" ? true : false}
                    onChange={(e) => setResultType(e.target.value)}
                  />
                  <span>Long</span>
                </label>
              </div>
            </div>
            {resultType === "bulletPoints" ? (
              <div className="formatSelector">
                <div className="formatLibraryArea">
                  <p>Bullet Library</p>
                  <ul>
                    {Array.from({ length: 7 }).map((_, i) => (
                      <li
                        key={i}
                        className={`bulletList ${
                          BulletType === i ? "active" : ""
                        }`}
                        onClick={() => setBulletType(i)}
                      >
                        <img
                          src={require(`assets/images/bullet library ${
                            i + 1
                          }.png`)}
                          alt="Bullet"
                        />
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="formatColorArea d-none">
                  <input
                    type="color"
                    id="colorPicker"
                    style={{ position: " absolute", opacity: 0 }}
                    className="colorPicker"
                  />
                  <label className="colorPicker" htmlFor="colorPicker">
                    <img src="assets/images/color wheel 1.png" alt="" />
                  </label>
                  <p>Color Picker</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="typingArea">
            <div className="inputBox">
              <input
                type="text"
                placeholder="Type Your Question Here...."
                id="sendBox"
                value={userQuestion}
                onChange={(e) => setUserQuestion(e.target.value)}
              />
            </div>
            <button
              className="sendBtn"
              id="sendBtn"
              onClick={() => {
                if (userQuestion !== "") {
                  if (chatType === "QA") {
                    SetQuestionChips(() => [
                      ...questionChips,
                      {
                        id: Number(new Date()),
                        data: userQuestion,
                        liekStatus: null,
                      },
                    ]);
                  } else {
                    SetQuestionChips(() => [
                      ...questionChips,
                      {
                        id: Number(new Date()),
                        data: userQuestion,
                        liekStatus: null,
                        status: resultType,
                        bulletPointStatus:
                          resultType === "bulletPoints" ? BulletType : null,
                      },
                    ]);
                  }
                  setUserQuestion("");
                }
              }}
            >
              <img
                src={require("assets/images/send icon white colour with blue bg.png")}
                alt="send"
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatAreaType1;
