import React from "react";
import { Outlet } from "react-router";
import PiUsecaseNav from "../pi-navs/piprojection_nav";

const UseCaseLayout = () => {
  return (
    <div>
      <PiUsecaseNav />
      <Outlet />
    </div>
  );
};

export default UseCaseLayout;
