import ChatAreaType1 from "component/chatarea";
import FileDropPreview from "component/filedrop_preview";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Summarization = () => {
  const navigate = useNavigate();

  const [category, SetCategory] = useState("Extractive");
  const [visibilityTab, setVisibilityTab] = useState(true);
  const [tabactive, setTabActive] = useState("Test out Presets");

  const [uploadedFiles, setUpoloadedFiles] = useState([]);
  const [SideSlideButtonView, SetSideSlideButtonView] = useState(false);
  const [SideSlideView, SetSideSlideView] = useState(false);
  const [documentName, setDocunemtName] = useState("Test out Presets");
  const QadocsCategoryData = [
    {
      img: "Home_Summarization_Black_1",
      active: "Home_Summarization_Blue_1",
      name: "Extractive",
      details:
        " Reduces a larger piece of content into its key points or essence while retaining its core meaning .Identifies key phrases or sentences in the original text and extracts them to form the summary. The end result is a summary that consists entirely of sentences or phrases from the original text",
    },
    {
      img: "Home_Summarization_Black_2",
      active: "Home_Summarization_Blue_2",
      name: "Abstractive",
      details:
        " Advanced technique similar to how a human might summarize a piece of text. Involves generating new phrases or sentences, rather than just extracting from the original text.",
    },
  ];

  useEffect(() => {
    if (uploadedFiles.length > 0) SetSideSlideButtonView(true);
  }, [uploadedFiles]);
  useEffect(() => {
    tabactive === "Test out Presets"
      ? setDocunemtName("Test with your data")
      : setDocunemtName("Test out Presets");
  }, [tabactive]);

  return (
    <>
      <div className="use_case_ex_container">
        <div className="qa_docs_container">
          {visibilityTab ? (
            <div className="qa_docs_selectionArea">
              <div className="qa_docs_tabHeadArea">
                <label className="qa_docs_tabHead active" htmlFor="try_presets">
                  <input
                    type="radio"
                    checked={tabactive === "Test out Presets" ? true : false}
                    id="try_presets"
                    name="qa_docs_tabHead"
                    onChange={(e) => {
                      e.target.checked
                        ? setTabActive("Test out Presets")
                        : setTabActive("Test with your data");
                    }}
                  />
                  <span>Test out Presets </span>
                </label>
                <label className="qa_docs_tabHead" htmlFor="try_own_data">
                  <input
                    type="radio"
                    id="try_own_data"
                    name="qa_docs_tabHead"
                    checked={tabactive === "Test with your data" ? true : false}
                    onChange={(e) => {
                      e.target.checked
                        ? setTabActive("Test with your data")
                        : setTabActive("Test out Presets");
                    }}
                  />
                  <span>Test with your data</span>
                </label>
              </div>
              <div className="qa_docs_tabbodyArea">
                {tabactive === "Test out Presets" ? (
                  <div className="qa_docs_tabbody ">
                    <div className="qa_docs_cards">
                      {QadocsCategoryData.map((item, i) => (
                        <div
                          key={i}
                          className={`qa_docs_card ${
                            category === item.name ? "active" : ""
                          }`}
                          onClick={() => SetCategory(item.name)}
                        >
                          <div className="cardImg">
                            <img
                              className={`dummy  ${
                                category === item.name ? "hide" : ""
                              }`}
                              src={require(`assets/images/${item.img}.png`)}
                              alt="Icon"
                            />
                            <img
                              className={`dummy  ${
                                category === item.name ? "" : "hide"
                              }`}
                              src={require(`assets/images/${item.active}.png`)}
                              alt="Icon"
                            />
                          </div>
                          <div className="card_body">
                            <p className="qa_docs_card_name">{item.name}</p>
                            <p className="qa_docs_card_desc">{item.details}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="qa_docs_generate">
                      <button
                        className="half_border_button"
                        onClick={() => {
                          setVisibilityTab(false);
                          // setDocunemtName("Test with your data");
                        }}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="qa_docs_tabbody">
                    <FileDropPreview
                      uploadedFiles={uploadedFiles}
                      setUpoloadedFiles={setUpoloadedFiles}
                    />

                    <div className="qa_docs_generate">
                      <button
                        className="half_border_button"
                        id="useCase_generateBtn"
                        onClick={() => {
                          if (uploadedFiles.length > 0) setVisibilityTab(false);
                        }}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <ChatAreaType1
                setVisibilityTab={setVisibilityTab}
                setTabActive={setTabActive}
                tabactive={tabactive}
                category={category}
                documentName={documentName}
                chatType={"Summarization"}
                tabHeads={["Test with your data", "Test out Presets"]}
              />
            </>
          )}
        </div>
      </div>
      {SideSlideButtonView && (
        <div className={`slideOver ${SideSlideView ? "slideActive" : ""}`}>
          <div
            className={`slideTrigger subBtn ${
              SideSlideView ? "slideTriggerHide" : ""
            }`}
            onClick={() => SetSideSlideView(true)}
          >
            <p>Add Or Remove Documents</p>
          </div>
          <div className="sliderContent">
            <div
              className={"slideClose"}
              onClick={() => SetSideSlideView(false)}
            >
              <img
                src={require("../../../../assets/images/close icon black.png")}
                alt="close"
              />
            </div>
            <div className="slideFileInput">
              <FileDropPreview
                uploadedFiles={uploadedFiles}
                setUpoloadedFiles={setUpoloadedFiles}
              />

              <div className="qa_docs_generate">
                <button
                  className="half_border_button"
                  id="useCase_generateBtn"
                  onClick={() => SetSideSlideView(false)}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Summarization;
