import React from 'react';
import ReactDOM from 'react-dom';
import classes from './OwnModalModal.module.css';
const BackDrop = () => {
  return (
    <div className={classes.backdrop}></div>
  )
}


const OwnModalModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(<BackDrop />, document.getElementById('backDrop'))}
      {ReactDOM.createPortal(props.children, document.getElementById('modal'))}
    </React.Fragment>

  )
}


export default OwnModalModal;