import { useContext } from 'react';
import OwnModalContext from 'pi-screen/own_modal/context/ownModalContext';
import classes from "./OwnModalList.module.css";
const OwnModalList = ({ data, questionType, question, handler }) => {
  const ctx = useContext(OwnModalContext);
  const optionOnchangeHandler = () => {
    handler(question, data.name)
  }
  const appropriateModalIndex = ctx.formDetail.findIndex((modal) => modal.question === question);
  const appropriateModal = ctx.formDetail[appropriateModalIndex];
  let inputChecked = false;
  let imgSrc = data.blImage;
  if (appropriateModal) {
    const optionNames = appropriateModal.option.map((opt) => opt.name);
    if (optionNames.includes(data.name)) {
      inputChecked = true;
      imgSrc = data.clrImage;
    } else {
      inputChecked = false;
      imgSrc = data.blImage;
    }
  }


  return (
    <li className={classes.modal_QA_Option_item}>
      <input
        type="checkbox"
        id={data.name}
        name={questionType}
        value={data.name}
        onChange={optionOnchangeHandler}
        checked={inputChecked}
      />
      <label className={classes.custom_label} htmlFor={data.name}>
        <div className={classes.gradient_wrapper}>
          <div className={classes.image_wrapper}>
            <img
              src={imgSrc}
              alt={data.name}
            />
          </div>
        </div>
        <p>{data.name}</p>
      </label>
    </li>
  )
}

export default OwnModalList;