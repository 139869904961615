import OwnModaPreviewListOptions from './OwnModalPreviwListOptions/OwnModalPreviewListOptions';
import classes from './OwnModalPreviewModalLists.module.css';
const OwnModalPreviewModalLists = ({ data }) => {
  console.log(data.question);
  return (
    <li className={classes.previewModalItem}>
      <div className={classes.preview_itemWrapper}>
        <p className={classes.previewQuestion}>{data.question}</p>
        <div className={classes.preview_Answer_container}>
          {
            data["name"] && <p className={classes.preview_Answer}>{data["name"]}</p>
          }
          {
            data["option"] && <OwnModaPreviewListOptions data={data["option"]} />
          }
        </div>
      </div>
    </li>
  )
}

export default OwnModalPreviewModalLists;