import React from 'react'

const AboutUs = () => {
  return (
    <section id="about_us_navigate">
    <div className="about_us_container">
      <div className="home_container_header">
        <h2>About Us</h2>
      </div>
      <div className="about_us_content_holder">
        <div className="about_us_left_content_container">
          <p>
            "Our team comprises of engineers, specialists, and pioneers with
            more than 20+ years of diverse industry experience across a
            broad spectrum of technologies and domains. We are eager to
            delve into the world of emerging technologies, with the aim of
            delivering cutting-edge platforms and products to our valued
            clientele.
          </p>
          <p>
            We understand the customers' pain areas and suggest tailor made
            solutions to cater to their need."
          </p>
        </div>
        <div className="about_us_right_content_container">
          <img
            src={require("../../../assets/images/aboutus_img.png")}
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  )
}

export default AboutUs