import React from "react";
import { Link } from "react-router-dom";

const setting = () => {
  return (
    <div className="use_case_ex_container forSettingsPage">
      <div className="use_case_ex_header">
        <h2 >Settings</h2>
      </div>
      <div className="use_case_ex_card_container">
        <div className="use_case_ex_card">
          <Link to="apikey" className="usecase_Card_holder">
            <div className="use_case_card_header">
              <div className="img_holder">
                <img className="filterDark" src={require("../../assets/images/apikey_icons.png")} alt="QA_black_icon" />
                <img
                  src={require("../../assets/images/apikey_icon_active.png")}
                  alt="QA_black_icon"
                />
              </div>
            </div>
            <div className="use_case_card_body">
              <h3>API Keys</h3>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default setting;
