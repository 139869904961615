import React from "react";
import { useNavigate } from "react-router";

const HomeBanner = () => {
  const navigate = useNavigate();
  return (
    <section className="landing_container" id="landing_container">
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-pause="false"
        data-bs-ride="carousel"
      >

<div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" ></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7"></button>

  </div>
        <div className="carousel-inner carosel_inner">
          <div className="carousel-item active" data-bs-interval="3500">
            <div className="banner_container">
              <div className="scroll_down">
                <a href="#enabling_navigate">
                  <img
                    src={require("../../../assets/images/Scroll_Icon.png")}
                    alt="Scroll_Icon"
                  />
                  Scroll Down
                </a>
              </div>
              <div className="banner_content">
                <div className="banner_content_cotainer">
                  <h2>Deliver New Generative AI capabilities In Minutes</h2>
                  <div className="btn_container">
                    <button
                      className="half_border_button"
                      onClick={() => {
                        navigate("Login");
                      }}
                    >
                      Playground
                      <img
                        src={require("../../../assets/images/arrow_icon_gradient.png")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="banner_img_container">
                  <img
                    src={require("../../../assets/images/Illustration.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="5000">
            <div className="banner_container">
              <div className="scroll_down">
                <a href="#enabling_navigate">
                  <img
                    src={require("../../../assets/images/Scroll_Icon.png")}
                    alt="Scroll_Icon"
                  />
                  Scroll Down
                </a>
              </div>
              <div className="banner_content">
                <div className="banner_content_cotainer">
                  <h2>
                    Driving enterprise growth using Generative AI leveraging
                    opensource, cloud & analytics
                  </h2>
                  <div className="btn_container">
                    <button
                      className="half_border_button"
                      onClick={() => {
                        navigate("Login");
                      }}
                    >
                      Playground
                      <img
                        src={require("../../../assets/images/arrow_icon_gradient.png")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="banner_img_container">
                  <img
                    src={require("../../../assets/images/banner_1.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3500">
            <div className="banner_container">
              <div className="scroll_down">
                <a href="#enabling_navigate">
                  <img
                    src={require("../../../assets/images/Scroll_Icon.png")}
                    alt="Scroll_Icon"
                  />
                  Scroll Down
                </a>
              </div>
              <div className="banner_content">
                <div className="banner_content_cotainer">
                  <h2>Enables enterprises to manage their own code base</h2>
                  <div className="btn_container">
                    <button
                      className="half_border_button"
                      onClick={() => {
                        navigate("Login");
                      }}
                    >
                      Playground
                      <img
                        src={require("../../../assets/images/arrow_icon_gradient.png")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="banner_img_container">
                  <img
                    src={require("../../../assets/images/banner_2.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="5000">
            <div className="banner_container">
              <div className="scroll_down">
                <a href="#enabling_navigate">
                  <img
                    src={require("../../../assets/images/Scroll_Icon.png")}
                    alt="Scroll_Icon"
                  />
                  Scroll Down
                </a>
              </div>
              <div className="banner_content">
                <div className="banner_content_cotainer">
                  <h2>
                    Create your own business specific LLMs in no code mode using
                    interactive UI in just few hours
                  </h2>
                  <div className="btn_container">
                    <button
                      className="half_border_button"
                      onClick={() => {
                        navigate("Login");
                      }}
                    >
                      Playground
                      <img
                        src={require("../../../assets/images/arrow_icon_gradient.png")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="banner_img_container">
                  <img
                    src={require("../../../assets/images/banner_3.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3500">
            <div className="banner_container">
              <div className="scroll_down">
                <a href="#enabling_navigate">
                  <img
                    src={require("../../../assets/images/Scroll_Icon.png")}
                    alt="Scroll_Icon"
                  />
                  Scroll Down
                </a>
              </div>
              <div className="banner_content">
                <div className="banner_content_cotainer">
                  <h2>Dynamic scaling on Prem or cloud of your choice</h2>
                  <div className="btn_container">
                    <button
                      className="half_border_button"
                      onClick={() => {
                        navigate("Login");
                      }}
                    >
                      Playground
                      <img
                        src={require("../../../assets/images/arrow_icon_gradient.png")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="banner_img_container">
                  <img
                    src={require("../../../assets/images/banner_4.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3500">
            <div className="banner_container">
              <div className="scroll_down">
                <a href="#enabling_navigate">
                  <img
                    src={require("../../../assets/images/Scroll_Icon.png")}
                    alt="Scroll_Icon"
                  />
                  Scroll Down
                </a>
              </div>
              <div className="banner_content">
                <div className="banner_content_cotainer">
                  <h2>Time to value realization by 60%</h2>
                  <div className="btn_container">
                    <button
                      className="half_border_button"
                      onClick={() => {
                        navigate("Login");
                      }}
                    >
                      Playground
                      <img
                        src={require("../../../assets/images/arrow_icon_gradient.png")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="banner_img_container">
                  <img
                    src={require("../../../assets/images/banner_5.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3500">
            <div className="banner_container">
              <div className="scroll_down">
                <a href="#enabling_navigate">
                  <img
                    src={require("../../../assets/images/Scroll_Icon.png")}
                    alt="Scroll_Icon"
                  />
                  Scroll Down
                </a>
              </div>
              <div className="banner_content">
                <div className="banner_content_cotainer">
                  <h2>Cost controlled & self managed platform</h2>
                  <div className="btn_container">
                    <button
                      className="half_border_button"
                      onClick={() => {
                        navigate("Login");
                      }}
                    >
                      Playground
                      <img
                        src={require("../../../assets/images/arrow_icon_gradient.png")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="banner_img_container">
                  <img
                    src={require("../../../assets/images/banner_6.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3500">
            <div className="banner_container">
              <div className="scroll_down">
                <a href="#enabling_navigate">
                  <img
                    src={require("../../../assets/images/Scroll_Icon.png")}
                    alt="Scroll_Icon"
                  />
                  Scroll Down
                </a>
              </div>
              <div className="banner_content">
                <div className="banner_content_cotainer">
                  <h2>
                    Bringing cost effective services tailored to your need
                  </h2>
                  <div className="btn_container">
                    <button
                      className="half_border_button"
                      onClick={() => {
                        navigate("Login");
                      }}
                    >
                      Playground
                      <img
                        src={require("../../../assets/images/arrow_icon_gradient.png")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="banner_img_container">
                  <img
                    src={require("../../../assets/images/banner_7.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
