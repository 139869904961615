import React from 'react';


export const OwnModalContext = React.createContext({
  foundationalHandler: () => { },
  fileHandler: () => { },
  fileDeleteHandler: () => { },
  normalOptionHandler: () => { },
  companyNameHandler: () => { },
  dataSourceHandler: () => { },
  radioInputHandler: () => { }
})




export default OwnModalContext;