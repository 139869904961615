import React, { useState } from "react";

const FileDropPreview = ({ uploadedFiles, setUpoloadedFiles }) => {
  const handleMultipleFiles = (value) => {
    // console.log(value);
    const modVal = value.map((item) => ({
      id: Number(new Date()) + Number(item.size) + Math.random() * 1000,
      data: item.name,
      size: formatFileSize(item.size),
    }));
    setUpoloadedFiles([...uploadedFiles, ...modVal]);
  };
  const formatFileSize = (size) => {
    const kb = 1024;
    const mb = kb * 1024;
    // console.log(size);
    if (size >= mb) {
      return (size / mb).toFixed(2) + " MB";
    } else if (size >= kb) {
      return (size / kb).toFixed(2) + " KB";
    } else {
      return size + " bytes";
    }
  };
  return (
    <div className="qa_fileDrop_area">
      <div className="file-uploadArea">
        <label className="file-upload custom-label" htmlFor="file-input">
          <div className="uploadImg">
            <img
              src={require("assets/images/upload icon.png")}
              alt="Upload Here"
            />
          </div>
          <h4>Drop Your Files Here</h4>
          <div className="subBtn">
            <p>Upload</p>
          </div>
          <input
            style={{ display: " none" }}
            type="file"
            id="file-input"
            multiple
            onChange={(e) => handleMultipleFiles([...e.target.files])}
          />
        </label>
        <ul className="file-list">
          {uploadedFiles?.map((item) => (
            <li className="file-item" key={item.id}>
              <span className="file-itemimg">
                <img src={require("assets/images/jpg icon.png")} alt="" />
              </span>
              <span className="file-name">
                <h6>{item.data}</h6>
                <p>{item.size}</p>
              </span>
              <span
                className="file-delete"
                onClick={() =>
                  setUpoloadedFiles([
                    ...uploadedFiles.filter((file) => file.id !== item.id),
                  ])
                }
              >
                <img
                  src={require("assets/images/close icon.png")}
                  alt="delete"
                />
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FileDropPreview;
