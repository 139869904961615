import React from "react";

const Invite = () => {
  return (
<>
<div className="sub_nav ad_content settingScreen_nav">
      <div className="sub_nav_left_Content">
     
        
        <h3 className="use_case_detailer">
          <img className="filterDark" src={require("../../assets/images/Invite_Icon.png")} alt="QA_black_icon" />Invite
          your Team
        </h3>
      </div>
    </div>
    <div className="settingsContent">
      <div className="invite_tableArea">
        <div className="invite_header">
          <div className="invite_tableArea_head">
            <h4>Organization Members</h4>
            <div>
              <button>
                <span>
                  <div>
                    <img src={require("../../assets/images/bullet library 6.png")} alt="" />
                  </div>
                </span>
                <span>Add Users</span>
              </button>
              <button>
                <span>
                  <div>
                    <img src={require("../../assets/images/Delete_Icon.png")} alt="" />
                  </div>
                </span>
                <span>Delete</span>
              </button>
            </div>
          </div>
        </div>
        <div className="invite_table">
          <table>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" />
                </th>
                <th>User Name</th>
                <th>Email Address</th>
                <th>Role</th>
                <th>Stats</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>Jebin</td>
                <td>jebin@admin.com</td>
                <td>Admin</td>
                <td>Waiting For Accept</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>Nirmal</td>
                <td>Nirmal@admin.com</td>
                <td>Write</td>
                <td>Request pending</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>Santhosh</td>
                <td>Santhosh@admin.com</td>
                <td>Read</td>
                <td>Request pending</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>Jebin</td>
                <td>jebin@admin.com</td>
                <td>Write</td>
                <td>Request pending</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>Nirmal</td>
                <td>Nirmal@admin.com</td>
                <td>Read</td>
                <td>Request pending</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div></>
    
  );
};

export default Invite;
