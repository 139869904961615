import { useState } from 'react';
import OwnModalFormHeader from 'pi-screen/own_modal/OwnModalUI/OwnModalFormHeader';
import OwnModalSubmitBtn from 'pi-screen/own_modal/OwnModalUI/OwnModalSubmitBtn';
import OwnModalTableRow from './OwnModalTableRows/OwnModalTableRows';
import classes from './DatasourceDataBaseModal.module.css';
const DatasourceDataBase = ({ closeHandler, submitHandler, question, modal }) => {
  const [formDetail, setFormDetail] = useState({
    dataSource: "",
    url: "",
    userName: "",
    password: "",
  })
  const [formError, setFormError] = useState(false);

  const dataSourceQuestions = [
    {
      question: "DataSource",
      outputType: "radio",
      options: ["Oracle", "MySQL", "MongoDB", "Postgress SQL"],
      questionType: "dataSource"
    },
    {
      question: "Enter the URL",
      outputType: "text",
      questionType: "url"
    },
    {
      question: "Enter Your Username",
      outputType: "text",
      questionType: "userName"
    },
    {
      question: "Enter Your Password",
      outputType: "password",
      questionType: "password"
    },
  ]

  const formSubmit = (e) => {
    e.preventDefault();
    // const validator = {
    //   url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
    //   userName: /^[a-zA-Z\-]+$/,
    //   password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
    // }
    // if (validator[key]) {
    //   if (formDetail[key].match(validator[key])) {
    //     checker.push(true);
    //   } else {
    //     checker.push(false);
    //   }
    // } else {
    //   checker.push(true);
    // }
    const checker = [];
    for (let key in formDetail) {


      if (formDetail[key].trim() !== '') {

        checker.push(true);
      } else {
        // setFocusInput(key);
        checker.push(false);
        // return

      }

    }
    const checkResult = checker.every((check) => check === true);
    // console.log(checker);
    // console.log(checkResult);
    if (checkResult) {
      setFormError(false);
      submitHandler(question, formDetail, modal);

    }
    if (!checkResult) {
      setFormError(true);
    }
  }

  const modalClose = () => {
    setFormError(false);
    closeHandler()
  }

  const inputChanger = (questionType, value) => {

    setFormDetail((prevState) => {
      return {
        ...prevState,
        [questionType]: value
      }
    })
  }


  return (
    <div className={classes.data_SourceModal}>
      <OwnModalFormHeader heading="DataBase" closeHandler={modalClose} />
      <form className={classes.dataSourceForm} onSubmit={formSubmit}>
        <div className={classes.tableWrapper}>
          <table className={classes.dataSourceTable}>
            <tbody>
              {
                dataSourceQuestions.map((dataSourceQuestion) => {
                  return (
                    <OwnModalTableRow isError={formError} data={dataSourceQuestion} key={dataSourceQuestion.question} value={formDetail[dataSourceQuestion.questionType]} questionType={dataSourceQuestion.questionType} inputChanger={inputChanger} />
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <OwnModalSubmitBtn name="Submit" extraClass={null} />
      </form>
    </div>
  )
}

export default DatasourceDataBase;