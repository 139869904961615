import React, { useState } from "react";
import { Link } from "react-router-dom";

const ApiKey = () => {
  const [tabView, setTabView] = useState("TrialKey");

  console.log(tabView);
  return (
    <>
      <div className="sub_nav ad_content settingScreen_nav">
        <div className="sub_nav_left_Content">
          <div className="breadcrums">
            <Link to="/setting">Settings</Link> {">"} <a href="#">API Keys</a>
          </div>
          <h3 className="use_case_detailer">
            <img
              src={require("../../../assets/images/APIkey_Icon.png")}
              alt=""
            />
            API Keys
          </h3>
        </div>
      </div>
      <div className="settingsContent">
        <div className="qa_docs_selectionArea ">
          <div className="subBtn">
            <p>2 Key can create by user</p>
          </div>
          <div className="qa_docs_tabHeadArea">
            <label className="qa_docs_tabHead active" htmlFor="TrialKey">
              <input
                type="radio"
                checked={tabView === "TrialKey" ? true : false}
                id="TrialKey"
                name="qa_docs_tabHead"
                onChange={(e) => {
                  e.target.checked
                    ? setTabView("TrialKey")
                    : setTabView("ProductionKey");
                }}
              />
              <span>Trial Key</span>
            </label>
            <label className="qa_docs_tabHead" htmlFor="ProductionKey">
              <input
                type="radio"
                checked={tabView === "ProductionKey" ? true : false}
                id="ProductionKey"
                name="qa_docs_tabHead"
                onChange={(e) =>
                  e.target.checked
                    ? setTabView("ProductionKey")
                    : setTabView("TrialKey")
                }
              />
              <span>Production Key</span>
            </label>
          </div>
          <div className="qa_docs_tabbodyArea">
            {tabView === "TrialKey" ? (
              <div className="qa_docs_tabbody active">
                <p className="subHead">Trial Key</p>
                <div className="keyInputArea">
                  <div className="inputContainers">
                    <div className="inputBox">
                      <input type="text" placeholder="Key Name" />
                    </div>
                    <div className="inputBox">
                      <input type="text" placeholder="Trial Public Key" />
                    </div>
                    <div className="inputBox">
                      <button className="half_border_button ">
                        Generate Key
                      </button>
                    </div>
                  </div>
                </div>
                <div className="invite_tableArea">
                  <div className="invite_header">
                    <div className="invite_tableArea_head">
                      <h4>Production Key List</h4>
                      <div>
                        <button>
                          <span>
                            <div>
                              <img
                                src={require("../../../assets/images/Delete_Icon.png")}
                                alt=""
                              />
                            </div>
                          </span>
                          <span>Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="invite_table API_table">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>Key Name</th>
                          <th>Trial Public Key</th>
                          <th>Created on</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>Jebin</td>
                          <td>pij-pro-14j</td>
                          <td>12/06/2023</td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>Nirmal</td>
                          <td>pij-pro-14j</td>
                          <td>12/06/2023</td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>Nirmal</td>
                          <td>pij-pro-14j</td>
                          <td>12/06/2023</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className="qa_docs_tabbody active">
                <p className="subHead">Production Key</p>
                <div className="keyInputArea">
                  <div className="inputContainers">
                    <div className="inputBox">
                      <input type="text" placeholder="Key Name" />
                    </div>
                    <div className="inputBox">
                      <input type="text" placeholder="Trial Public Key" />
                    </div>
                    <div className="inputBox"></div>
                  </div>
                  <div className="verifyArea">
                    <div className="checkBox">
                      <input type="checkbox" id="apiVerify" />
                      <label htmlFor="apiVerify">
                        <span>I agree to the</span>
                        <span>
                          <a href="#">Terms & Condition</a>
                        </span>
                        <span>and</span>
                        <span>
                          <a href="#">Privacy Policy.</a>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="generateKey">
                    <button className="half_border_button">Generate Key</button>
                  </div>
                </div>
                <div className="invite_tableArea">
                  <div className="invite_header">
                    <div className="invite_tableArea_head">
                      <h4>Production Key List</h4>
                      <div>
                        <button>
                          <span>
                            <div>
                              <img
                                src={require("../../../assets/images/Delete_Icon.png")}
                                alt=""
                              />
                            </div>
                          </span>
                          <span>Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="invite_table API_table">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>Individual Name</th>
                          <th>Company Name</th>
                          <th>Production Public Key</th>
                          <th>Created on</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>Jebin</td>
                          <td>Techdew</td>
                          <td>pij-pro-14j</td>
                          <td>12/06/2023</td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>Nirmal</td>
                          <td>Techdew</td>
                          <td>pij-pro-14j</td>
                          <td>12/06/2023</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiKey;
