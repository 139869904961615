import { useState, useContext } from "react";
import OwnModalFoundationalHeadList from "./OwnModalFoundationalHeadList/OwnModalFoundationalHeadList";
import OwnModalFoundationalSubCategory from "./OwnModalFoundationalSubCategory/OwnModalFoundationalSubCategory";
import OwnModalFoundationalChips from "./OwnModalFoundationalSubCategory/OwnModalFoundationalChips/OwnModalFoundationalChips";
import { OwnModalContext } from "pi-screen/own_modal/context/ownModalContext";
import classes from './OwnModalFoundational.module.css';
import FileUploader from "./FileUploader/FileUploader";
import FileDropPreview from "component/filedrop_preview";
const OwnModalFoundational = ({ data, question, activetabListItem }) => {
  const [activeTab, setTabActive] = useState(activetabListItem);
  const [subCategory, setSubCategory] = useState('Opensource');

  // const [activeTab, setTabActive] = useState("OpenSource");
  const ctx = useContext(OwnModalContext);
  const activeSubCategoryIndex = data.mainCategory.findIndex((contentdata) => contentdata.name === activeTab);
  const activeSubCategory = data.mainCategory[activeSubCategoryIndex];
  // const activeSubList = activeSubCategory.subCategory;
  const mainTabHandler = (mainCategory) => {
    setTabActive(mainCategory);
  }
  const changeSubCategory = (subList) => {
    setSubCategory(subList)
  }
  const foundationalDataHandler = (categoryData, category, subCategory, subList, path) => {
    ctx.foundationalHandler(categoryData, category, subCategory, question, subList, path)
  }
  const foundationalSubCategoryDataHandler = (subCategory) => {
    ctx.foundationalHandler(activeSubCategory, null, subCategory, question, null, null)
  }
  const fileHandler = (files) => {
    ctx.fileHandler(question, activeTab, files)
  }
  const fileRemove = (id) => {
    ctx.fileDeleteHandler(id, question, activeTab);
  }
  const existingFoundationalDataIndex = ctx.formDetail.findIndex((founData) => founData.question === question);
  const existingModal = ctx.formDetail[existingFoundationalDataIndex];
  let tempSubCategory = [];
  if (existingModal) {
    console.log(activeSubCategory);
    const existingMainCategoryIndex = existingModal.option.findIndex((mainCate) => mainCate.name === activeSubCategory.name);
    if (existingModal.option[existingMainCategoryIndex]) {

      if (existingModal.option[existingMainCategoryIndex].subCategory) {
        tempSubCategory = existingModal.option[existingMainCategoryIndex].subCategory;
      }

      if (existingModal.option[existingMainCategoryIndex].files) {
        tempSubCategory = existingModal.option[existingMainCategoryIndex].files;
      }


    }
  }
  return (
    <div className={classes.foundational_content_wrapper}>
      <OwnModalFoundationalHeadList tabList={data.mainCategory} activeTab={activeTab} tabHandler={mainTabHandler} question={question} />
      <div className={classes.foundational_subCategoru_container}>
        {activeSubCategory["category"] && <OwnModalFoundationalSubCategory category={activeSubCategory} activeCategory={subCategory} activeCategoryHandler={changeSubCategory} dataHandler={foundationalDataHandler} question={question} />}
        {activeSubCategory["subCategory"] && <OwnModalFoundationalChips storedChips={tempSubCategory} subList={activeSubCategory} chipsHandler={foundationalSubCategoryDataHandler} />}

        {activeTab === "Bring your own model" && <FileUploader handler={fileHandler} deleteHandler={fileRemove} uploadedFiles={tempSubCategory} />}
      </div>
    </div>
  )
}

export default OwnModalFoundational;