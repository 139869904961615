import React from "react";

const HomeEnterprises = () => {
  return (
    <section id="enterprise_Navigate">
      <div className="enterprises_usecase_Container">
        <div className="home_container_header">
          <h2>Enterprise Use Cases</h2>
        </div>
        <div className="enterprises_chip_container">
          <ul>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_Retail_Black_1.png")}
                alt="Home_Enterprises_Retail_Black_1"
              />
              <p>Retail</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_ConsumerGoods_Black_2.png")}
                alt="Home_Enterprises_ConsumerGoods_Black_2.png"
              />
              <p>Consumer Goods</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_Banking,FinanceandInsurance_Black_3.png")}
                alt="Home_Enterprises_Banking"
              />
              <p>Banking ,Finance and Insurance</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_Energy&Utilies_Black_4.png")}
                alt="chip_Energy_Icon.png"
              />
              <p>Energy & Utilities</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_Communication,MediaandTechnology_Black_5.png")}
                alt="chip_Communication_Icon.png"
              />
              <p>Communication , Media and Technology</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_Healthcares_Black_6.png")}
                alt="chip_Heaithcare_Icon.png"
              />
              <p>Healthcare</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_LifeScience_Black_7.png")}
                alt="chip_Lifescience_Icon.png"
              />
              <p>LifeScience</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_Travel&Hospitalty_Black_2.png")}
                alt="chip_Travel_Icon.png"
              />
              <p>Travel & Hospitality</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_Manufacturing&Logistics_Black_8.png")}
                alt="chip_Manufacturing_Icon.png"
              />
              <p>Manufacturing & Logistics</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_Government_Black_9.png")}
                alt="chip_Government_Icon.png"
              />
              <p>Government</p>
            </li>
            <li>
              <img
                src={require("../../../assets/images/Home_Enterprises_Telecommunications_Black_10.png")}
                alt="chip_Telecommunication_Icon.png"
              />
              <p>Telecommunications</p>
            </li>
          </ul>
        </div>

        <div className="enterprises_flip_card_container">
          <div className="enterprises_flip_card">
            <div className="card_inner">
              <div className="card_img card_front">
                <div className="img_holder">
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Blue_1.png")}
                    alt=""
                  />
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Black_1.png")}
                    alt=""
                  />
                </div>
                <h4>Customer Services</h4>
              </div>
              <div className="card_back">
                <h4>Customer Services</h4>
                <p>
                  Creates intelligent AI assistant that can handle a wide range
                  of customer service interactions. The AI assistants can be
                  custom trained to understand and generate human-like text,
                  providing more natural and efficient customer service.
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="enterprises_flip_card">
            <div className="card_inner">
              <div className="card_img card_front">
                <div className="img_holder">
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Blue_2.png")}
                    alt=""
                  />
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Black_2.png")}
                    alt=""
                  />
                </div>
                <h4>Documentation Generation</h4>
              </div>
              <div className="card_back">
                <h4>Documentation Generation</h4>
                <p>
                  Automates the creation of certain types of documents or
                  reports, reducing the time and effort required to produce
                  these documents.
                </p>
              </div>
            </div>
          </div>
          <div className="enterprises_flip_card">
            <div className="card_inner">
              <div className="card_img card_front">
                <div className="img_holder">
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Blue_3.png")}
                    alt=""
                  />
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Black_3.png")}
                    alt=""
                  />
                </div>
                <h4>Personalized Marketing & New Product Creation</h4>
              </div>
              <div className="card_back">
                <h4>Personalized Marketing & New Product Creation</h4>
                <p>
                  Creates personalized advice or product recommendations based
                  on a customer's profile and behavior. This would be similar to
                  how e-commerce sites recommend products based on browsing
                  history.
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="enterprises_flip_card">
            <div className="card_inner">
              <div className="card_img card_front">
                <div className="img_holder">
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Blue_4.png")}
                    alt=""
                  />
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Black_4.png")}
                    alt=""
                  />
                </div>
                <h4>Data Augmentation</h4>
              </div>
              <div className="card_back">
                <h4>Data Augmentation</h4>
                <p>
                  Creates synthetic data that mimics current data set and
                  provides more balanced datasets for training other AI models.
                </p>
              </div>
            </div>
          </div>
          <div className="enterprises_flip_card">
            <div className="card_inner">
              <div className="card_img card_front">
                <div className="img_holder">
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Blue_5.png")}
                    alt=""
                  />
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Black_5.png")}
                    alt=""
                  />
                </div>
                <h4>Voice Activated real time business Intelligence</h4>
              </div>
              <div className="card_back">
                <h4>Voice Activated real time business Intelligence</h4>
                <p>
                  Enables business intelligence information through voice prompt
                  in real time with no dependency on dashboards and reports
                </p>
              </div>
            </div>
          </div>
          <div className="enterprises_flip_card">
            <div className="card_inner">
              <div className="card_img card_front">
                <div className="img_holder">
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Blue_6.png")}
                    alt=""
                  />
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Black_6.png")}
                    alt=""
                  />
                </div>
                <h4>Incident Response</h4>
              </div>
              <div className="card_back">
                <h4>Incident Response</h4>
                <p>
                  Assists in responding to security incidents by automating
                  certain tasks, such as isolating affected systems or blocking
                  malicious IP addresses.
                </p>
              </div>
            </div>
          </div>
          <div className="enterprises_flip_card">
            <div className="card_inner">
              <div className="card_img card_front">
                <div className="img_holder">
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Blue_7.png")}
                    alt=""
                  />
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Black_7.png")}
                    alt=""
                  />
                </div>
                <h4>Security Automation and Orchestration</h4>
              </div>
              <div className="card_back">
                <h4>Security Automation and Orchestration</h4>
                <p>
                  Automates repetitive tasks and coordinate security measures
                  across different platforms and systems, increasing the
                  efficiency and effectiveness of security operations.
                </p>
              </div>
            </div>
          </div>
          <div className="enterprises_flip_card">
            <div className="card_inner">
              <div className="card_img card_front">
                <div className="img_holder">
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Blue_8.png")}
                    alt=""
                  />
                  <img
                    src={require("../../../assets/images/Home_Enterprises_Black_8.png")}
                    alt=""
                  />
                </div>
                <h4>Threat Intelligence</h4>
              </div>
              <div className="card_back">
                <h4>Threat Intelligence</h4>
                <p>
                  Gathers, organizes, and monitors the system for threat
                  intelligence data from various sources, aiding in the early
                  detection of potential security threats.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeEnterprises;
