import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { setPiTheme } from "store/actions";

const PiUsecaseNav = () => {
  const navLinksRef = useRef(null);
  const navigate = useNavigate();
  const theme = useSelector((state) => state.PiThemeReducer.theme);

  const dispatch = useDispatch();
  const handleClick = () => {
    const navLinks = navLinksRef.current;
    console.log(navLinks);

    if (navLinks.scrollHeight) {
      navLinks.style.height = `${navLinks.scrollHeight}px`;
    } else {
      navLinks.style.height = 0;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      const navLinks = navLinksRef.current;
      if (window.innerWidth <= 992 && navLinks.clientHeight) {
        navLinks.style.height = 0;
      }
    };

    const handleResize = () => {
      const navLinks = navLinksRef.current;
      if (window.innerWidth >= 992 && navLinks.clientHeight) {
        navLinks.style.height = "unset";
      }
      // Uncomment the below code block if you need to handle resize event for screens below 992 width.
      // if (window.innerWidth <= 992 && navLinks.clientHeight) {
      //   navLinks.style.height = 0;
      // }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const Location = useLocation();
  const [navType, setNavType] = useState("User");
  useEffect(() => {
    console.log(window.location.href, location.pathname);
    Location.pathname === "/usecases" ||
    Location.pathname === "/uiDriven" ||
    Location.pathname === "/ownModel" ||
    Location.pathname === "/enterprises" ||
    location.pathname.includes("marketplace")
      ? setNavType("User")
      : setNavType("");
  }, [Location.pathname]);
  const navOptions = {
    qadocs: {
      caseType: "Use Cases",
      subBreadcum: "QA Over Docs",
      img: "QA_black_icon",
    },
    summarization: {
      caseType: "Use Cases",
      subBreadcum: "Summarization",
      img: "summarization_Black_icon",
    },
    Text_To_Image: {
      caseType: "Use Cases",
      subBreadcum: "Text to Image / Pdf",
      img: "text_to_image_Pdf_black_icon",
    },
    Text_To_Video: {
      caseType: "Use Cases",
      subBreadcum: "Text to Video",
      img: "Text_to_Video_black_icon",
    },
    video: {
      caseType: "Use Cases",
      subBreadcum: "Video",
      img: "video_black_icon",
    },
    "Agent Simulation": {
      caseType: "Use Cases",
      subBreadcum: "Video",
      img: "video_black_icon",
    },
  };
  const URLpath = location.pathname?.split("/");
  const URLpath_lastName = URLpath?.[URLpath?.length - 1];
  // URLpath[URLpath.length - 1] === "uiDriven"
  //   ? ""
  //   : URLpath[URLpath.length - 1];
  console.log(URLpath_lastName);
  console.log(navType);
  console.log(Location.pathname);
  return (
    <>
      <header className="usecase">
        <nav>
          <div className="logo_container">
            <Link to={"/usecases"}>
              <img
                src={require(`../../assets/images/pi_logo_${theme}.png`)}
                alt="Logo"
              />
            </Link>
          </div>
          <button className="ham-icon" onClick={handleClick}>
            <img
              src={require("../../assets/images/ham_Menu.png")}
              alt="ham_Menu"
            />
          </button>
          <ul className="nav_list" ref={navLinksRef}>
            <li>
              <NavLink
                to={"usecases"}
                className="nav_link "
                activeclassname={"active"}
              >
                Use Cases
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"enterprises"}
                className="nav_link"
                activeclassname={"active"}
              >
                My Enterprises
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"invite"}
                className="nav_link"
                activeclassname={"active"}
              >
                Invite your Team
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"Billing"}
                className="nav_link"
                activeclassname={"active"}
              >
                Billing
              </NavLink>
            </li>
            <li>
              <a href="#" className="nav_link">
                Documentation
              </a>
            </li>
            <li className="hider">
              <a href="#" className="nav_link " activeclassname={"active"}>
                Profile
              </a>
            </li>
            <li>
              <NavLink
                to={"setting"}
                className="nav_link"
                activeclassname={"active"}
              >
                Settings
              </NavLink>
            </li>
            <li className="hider">
              <NavLink to={""} className="nav_link " activeclassname={"active"}>
                Logout
              </NavLink>
            </li>
          </ul>
          <ul className="nav_other_options">
            <li>
              <button className="bookMark_btn">
                <img
                  className="filterDark"
                  src={require("../../assets/images/Book_Mark.png")}
                  alt="Bookmark"
                />
              </button>
            </li>
            <li>
              <button
                className="screenTheme_btn"
                onClick={() => {
                  dispatch(setPiTheme(theme === "dark" ? "Light" : "dark"));
                  document.documentElement.setAttribute("data-theme", theme === "dark" ? "Light" : "dark");
                }}
              >
                <img
                  src={require(`../../assets/images/${
                    theme === "dark" ? "Light" : "dark"
                  }.png`)}
                  alt="Theme"
                />
              </button>
            </li>
            <li>
              <button className="profile_btn">
                <span>John Smith</span>
                <img
                  src={require("../../assets/images/Profile_Img.png")}
                  alt="Profile"
                />
              </button>
            </li>
            <li>
              <button className="logout_btn filterDark">
                <a href="/#">
                  <img
                    src={require("../../assets/images/logout.png")}
                    alt="Logout"
                  />
                </a>
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <div className="sub_nav ad_content">
        <div className="sub_nav_left_Content">
          {navType === "User" && <h2 className="jhon">Hello John!</h2>}
          {navType === "" && (
            <>
              {console.log(navOptions?.[URLpath_lastName])}
              <div className="breadcrums">
                <Link to="/usecases">
                  {navOptions?.[URLpath_lastName]?.caseType}
                </Link>
                {" > "}
                <a href="#"> {navOptions?.[URLpath_lastName]?.subBreadcum}</a>
              </div>
              <h3 className="use_case_detailer">
                {navOptions?.[URLpath_lastName]?.img && (
                  <img
                    className="filterDark"
                    src={require(`assets/images/${navOptions?.[URLpath_lastName]?.img}.png`)}
                    alt="QA_black_icon"
                  />
                )}

                {navOptions?.[URLpath_lastName]?.subBreadcum}
              </h3>
            </>
          )}
        </div>
        <div className="sub_nav_right_Content">
          <p>Create Your Own Model</p>
          <div className="btn_container">
            <button
              className="half_border_button"
              type="button"
              aria-expanded="false"
              onClick={() => navigate("ownModel")}
              disabled={Location.pathname === "/ownModel" ? true : false}
            >
              <img
                className="icon filterDark"
                src={require("../../assets/images/q_a_icon.png")}
                alt=""
              />
              <span>Q&A Based</span>
              <img
                className="arrow filterDark"
                src={require("../../assets/images/arrow_icon_gradient.png")}
                alt=""
              />
            </button>
            <button
              className="half_border_button"
              type="button"
              aria-expanded="false"
              onClick={() => navigate("uiDriven")}
              disabled={Location.pathname === "/uiDriven" ? true : false}
            >
              <img
                className="icon filterDark"
                src={require("../../assets/images/UI_icon.png")}
                alt=""
              />
              <span>UI Driven</span>
              <img
                className="arrow filterDark"
                src={require("../../assets/images/arrow_icon_gradient.png")}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PiUsecaseNav;
