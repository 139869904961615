import React from "react";

const HomeProduct = () => {
  return (
    <section id="Product_Navigate">
      <div className="product_container">
        <div className="home_container_header">
          <h2 className="product">Products & Use Cases</h2>
        </div>
        <div className="product_tab_container">
          <div className="product_btn_container">
            <div className="nav-tabs nav_tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_QAOverDocs_White_1.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_QAOverDocs_Black_1.png")}
                  alt="product_Qa_Icon"
                />
                QA Over Docs
              </button>
              <button
                className="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_Summarization_White_2.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_Summarization_Black_2.png")}
                  alt="product_Qa_Icon"
                />
                Summarization
              </button>
              <button
                className="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_TextToImage-PDF_White_3.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_TextToImage-PDF_Black_3.png")}
                  alt="product_Qa_Icon"
                />
                Text to Image /Pdf
              </button>
              <button
                className="nav-link"
                id="nav-disabled-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-disabled"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_TextToVideo_White_4.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_TextToVideo_Black_4.png")}
                  alt="product_Qa_Icon"
                />
                Text to Video
              </button>
              <button
                className="nav-link"
                id="nav-Video-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-Video"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_Video_White_5.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_Video_Black_5.png")}
                  alt="product_Qa_Icon"
                />
                Video
              </button>
              <button
                className="nav-link"
                id="Agent_yab"
                data-bs-toggle="tab"
                data-bs-target="#Agent"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_AgentSimulation_White_6.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_AgentSimulation_Black_6.png")}
                  alt="product_Qa_Icon"
                />
                Agent Simulation
              </button>
              <button
                className="nav-link"
                id="Chatbots_tab"
                data-bs-toggle="tab"
                data-bs-target="#Chatbots"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_Chatbot&PersonalAssistant_White_7.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_Chatbot&PersonalAssistant_Black_7.png")}
                  alt="product_Qa_Icon"
                />
                Chatbots & Personal Assistant
              </button>
              <button
                className="nav-link"
                id="Autonomous-tab"
                data-bs-toggle="tab"
                data-bs-target="#Autonomous_Agents"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_AutonomousAgents_White_8.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_AutonomousAgents_Black_8.png")}
                  alt="product_Qa_Icon"
                />
                Autonomous Agents
              </button>
              <button
                className="nav-link"
                id="Querys_tab"
                data-bs-toggle="tab"
                data-bs-target="#Querys"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_QueryingTabularData_White_9.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_QueryingTabularData_Black_9.png")}
                  alt="product_Qa_Icon"
                />
                Querying Tabular Data
              </button>
              <button
                className="nav-link"
                id="Api-tab"
                data-bs-toggle="tab"
                data-bs-target="#API_integration"
                type="button"
              >
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_APIIntegration_White_10.png")}
                  alt="product_Qa_Icon"
                />
                <img
                  src={require("../../../assets/images/Home_ProductUsecase_APIIntegration_Black_10.png")}
                  alt="product_Qa_Icon"
                />
                API Integration
              </button>
            </div>
          </div>
          <div
            className="tab-content product_content_container"
            id="nav-tabContent"
          >
            <div className="tab-pane fade show active" id="nav-home">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_QA_Blue_1.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_QA_Black_1.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Document QA</h5>
                    <p>Fetches from a document</p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_QA_Blue_2.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_QA_Black_2.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Table /DB QA</h5>
                    <p>
                      Fetches data against structured data such as databases or
                      spreadsheets
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_QA_Blue_3.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_QA_Black_3.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Text QA</h5>
                    <p>
                      Fetches from unstructured data like natural language text
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_QA_Blue_4.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_QA_Black_4.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Semi Structured data QA</h5>
                    <p>
                      Fetches from data that has some level of structure but
                      isn't fully structured. For e.g, a webpage with text,
                      links, and metadata
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_QA_Blue_5.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_QA_Black_5.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Q&A</h5>
                    <p>
                      Queries that users submit to the system in the form of
                      natural language sentences or more structured queries
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-profile">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_Summarization_Blue_1.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_Summarization_Black_1.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Extractive</h5>
                    <p>
                      Identifies key phrases or sentences in the original text
                      and extracts them to form the summary. The end result is a
                      summary that consists entirely of sentences or phrases
                      from the original text
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_Summarization_Blue_2.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_Summarization_Black_2.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Abstractive</h5>
                    <p>
                      Advanced technique similar to how a human might summarize
                      a piece of text. Involves generating new phrases or
                      sentences, rather than just extracting from the original
                      text.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-contact">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_TextToImage-Pdf_Blue_1.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_TextToImage-Pdf_Black_1.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Transform to Image</h5>
                    <p>
                      Generates images from text descriptions, converting data
                      into visual plots or graphs, or even transforming one
                      image into another using techniques like neural style
                      transfer
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_TextToImage-Pdf_Blue_1.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_TextToImage-Pdf_Black_2.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Transform to PDF</h5>
                    <p>
                      Converts data into a format suitable for creating a PDF
                      document.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-disabled">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_TextToVideo_Blue_1.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_TextToVideo_Black_1.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Simple forms</h5>
                    <p>
                      The simplest form of text-to-video might involve
                      displaying the text in a video format, perhaps with some
                      background images or animations, and possibly with an
                      audio narration of the text.
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_TextToVideo_Blue_2.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_TextToVideo_Black_2.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Advanced forms</h5>
                    <p>
                      More advanced forms of text-to-video could involve
                      creating entirely new video scenes based on the text. For
                      instance, if the text is a script for a short film, the AI
                      might generate a video where animated characters act out
                      the script.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-Video">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_Video_Blue_1.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_Video_Black_1.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Video Summarization</h5>
                    <p>
                      Creates a short summary or highlight reel from a longer
                      video. AI can help identify the key moments in a video
                      that should be included in the summary.
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_Video_Blue_2.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_Video_Black_2.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Video Captioning and Description</h5>
                    <p>
                      Generates captions for videos which is useful for
                      accessibility
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Agent">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_AgentSimulation_Blue_1.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_AgentSimulation_Black_1.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Agent</h5>
                    <p>
                      Creates artificial agents that interact with a simulated
                      environment and are programmed to perform tasks or make
                      decisions based on certain rules or learning algorithms
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Chatbots">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_Chatbots&PersonalAssistant_Blue_1.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_Chatbots&PersonalAssistant_Black_1.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Chatbots</h5>
                    <p>
                      AI systems designed to simulate human-like conversation,
                      often used in customer service to answer common queries,
                      guide users through processes, or provide information
                      about products and services
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_Chatbots&PersonalAssistant_Blue_2.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_Chatbots&PersonalAssistant_Black_2.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Personal Assistant</h5>
                    <p>
                      Also known as virtual assistants or intelligent personal
                      assistants, that performs a wide range of tasks based on
                      voice commands
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Autonomous_Agents">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_AgentSimulation_Blue_1.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_AgentSimulation_Black_1.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Autonomous Agents</h5>
                    <p>
                      Operates independently, make decisions, and perform tasks
                      without the need for continuous human guidance
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Querys">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_QA_Blue_2.png")}
                      alt=""
                    />
                    <img
                      src={require("../../../assets/images/Home_QA_Black_2.png")}
                      alt="docs_qa"
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Querying Tabular Data</h5>
                    <p>
                      Reduces a larger piece of content into its key points or
                      essence while retaining its core meaning
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="API_integration">
              <div className="product_card_container">
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Blue_1.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Black_1.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Machine Learning APIs</h5>
                    <p>
                      Predefined machine learning APIs can be used to train own
                      models using the platform's resources, or pre-trained
                      models can be used directly that are provided by the
                      platform.
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Blue_2.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Black_2.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Natural Language Processing APIs</h5>
                    <p>
                      Provides access to natural language processing services,
                      which can analyze text for sentiment, extract key phrases,
                      classNameify text into categories, translate text between
                      languages, and more
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Blue_3.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Black_3.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Speech Recognition APIs</h5>
                    <p>
                      These APIs can convert spoken language into written text,
                      useful for transcribing audio, enabling voice control in
                      applications, and more
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Blue_4.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Black_4.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Image & Video Recognition APIs</h5>
                    <p>
                      These APIs can analyze images or videos to identify
                      objects, people, text, or actions
                    </p>
                  </div>
                </div>
                <div className="product_card">
                  <div className="img_holder">
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Blue_5.png")}
                      alt="docs_qa"
                    />
                    <img
                      src={require("../../../assets/images/Home_APIIntegrationt_Black_5.png")}
                      alt=""
                    />
                  </div>
                  <div className="product_card_body">
                    <h5>Chatbot APIs</h5>
                    <p>
                      These APIs can be used to integrate the chatbot with their
                      own applications or with third-party services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product_accordian_tab_container">
          <div className="accordian_container">
            <div className="home_container_header">
              <h2>Products & Use Cases</h2>
            </div>
            <div className="accordion accordian_holder" id="accordionExample">
              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_1.png")}
                      alt=""
                    />{" "}
                    QA Over Docs
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_QA_Blue_1.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_QA_Black_1.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Document QA</h5>
                          <p>Fetches from a document</p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_QA_Blue_2.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_QA_Black_2.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Table /DB QA</h5>
                          <p>
                            Fetches data against structured data such as
                            databases or spreadsheets
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_QA_Blue_3.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_QA_Black_3.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Text QA</h5>
                          <p>
                            Fetches from unstructured data like natural language
                            text
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_QA_Blue_4.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_QA_Black_4.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Semi Structured data QA</h5>
                          <p>
                            Fetches from data that has some level of structure
                            but isn't fully structured. For e.g, a webpage with
                            text, links, and metadata
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_QA_Blue_5.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_QA_Black_5.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Q&A</h5>
                          <p>
                            Queries that users submit to the system in the form
                            of natural language sentences or more structured
                            queries
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsetwo"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_2.png")}
                      alt=""
                    />
                    Summarization
                  </button>
                </h2>
                <div
                  id="collapsetwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_Summarization_Blue_1.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_Summarization_Black_1.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Extractive</h5>
                          <p>
                            Identifies key phrases or sentences in the original
                            text and extracts them to form the summary. The end
                            result is a summary that consists entirely of
                            sentences or phrases from the original text
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_Summarization_Blue_2.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_Summarization_Black_2.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Abstractive</h5>
                          <p>
                            Advanced technique similar to how a human might
                            summarize a piece of text. Involves generating new
                            phrases or sentences, rather than just extracting
                            from the original text.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsethree"
                    aria-expanded="true"
                    aria-controls="collapsethree"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_3.png")}
                      alt=""
                    />{" "}
                    Text to Image/Pdf
                  </button>
                </h2>
                <div
                  id="collapsethree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_TextToImage-Pdf_Blue_1.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_TextToImage-Pdf_Black_1.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Transform to Image</h5>
                          <p>
                            Generates images from text descriptions, converting
                            data into visual plots or graphs, or even
                            transforming one image into another using techniques
                            like neural style transfer
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_TextToImage-Pdf_Blue_1.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_TextToImage-Pdf_Black_2.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Transform to PDF</h5>
                          <p>
                            Converts data into a format suitable for creating a
                            PDF document.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="true"
                    aria-controls="collapsefour"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_4.png")}
                      alt=""
                    />{" "}
                    Text to Video
                  </button>
                </h2>
                <div
                  id="collapsefour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_TextToVideo_Blue_1.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_TextToVideo_Black_1.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Simple forms</h5>
                          <p>
                            The simplest form of text-to-video might involve
                            displaying the text in a video format, perhaps with
                            some background images or animations, and possibly
                            with an audio narration of the text.
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_TextToVideo_Blue_2.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_TextToVideo_Black_2.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Advanced forms</h5>
                          <p>
                            More advanced forms of text-to-video could involve
                            creating entirely new video scenes based on the
                            text. For instance, if the text is a script for a
                            short film, the AI might generate a video where
                            animated characters act out the script.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="true"
                    aria-controls="collapsefive"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_5.png")}
                      alt=""
                    />{" "}
                    Video
                  </button>
                </h2>
                <div
                  id="collapsefive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_Video_Blue_1.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_Video_Black_1.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Video Summarization</h5>
                          <p>
                            Creates a short summary or highlight reel from a
                            longer video. AI can help identify the key moments
                            in a video that should be included in the summary.
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_Video_Blue_2.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_Video_Black_2.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Video Captioning and Description</h5>
                          <p>
                            Generates captions for videos which is useful for
                            accessibility
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsesix"
                    aria-expanded="true"
                    aria-controls="collapsesix"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_6.png")}
                      alt=""
                    />{" "}
                    Agent Simulation
                  </button>
                </h2>
                <div
                  id="collapsesix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_AgentSimulation_Blue_1.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_AgentSimulation_Black_1.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Agent</h5>
                          <p>
                            Creates artificial agents that interact with a
                            simulated environment and are programmed to perform
                            tasks or make decisions based on certain rules or
                            learning algorithms
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseseven"
                    aria-expanded="true"
                    aria-controls="collapseseven"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_7.png")}
                      alt=""
                    />{" "}
                    Chatbots & Personal Assistant
                  </button>
                </h2>
                <div
                  id="collapseseven"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_Chatbots&PersonalAssistant_Blue_1.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_Chatbots&PersonalAssistant_Black_1.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Chatbots</h5>
                          <p>
                            AI systems designed to simulate human-like
                            conversation, often used in customer service to
                            answer common queries, guide users through
                            processes, or provide information about products and
                            services
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_Chatbots&PersonalAssistant_Blue_2.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_Chatbots&PersonalAssistant_Black_2.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Personal Assistant</h5>
                          <p>
                            Also known as virtual assistants or intelligent
                            personal assistants, that performs a wide range of
                            tasks based on voice commands
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseeight"
                    aria-expanded="true"
                    aria-controls="collapseeight"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_8.png")}
                      alt=""
                    />{" "}
                    Autonomous Agents
                  </button>
                </h2>
                <div
                  id="collapseeight"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                        <img
                            src={require("../../../assets/images/Home_AgentSimulation_Blue_1.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_AgentSimulation_Black_1.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Autonomous Agents</h5>
                          <p>
                            Operates independently, make decisions, and perform
                            tasks without the need for continuous human guidance
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsenine"
                    aria-expanded="true"
                    aria-controls="collapsenine"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_9.png")}
                      alt=""
                    />{" "}
                    Querying Tabular Data
                  </button>
                </h2>
                <div
                  id="collapsenine"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_QA_Blue_2.png")}
                            alt=""
                          />
                          <img
                            src={require("../../../assets/images/Home_QA_Black_2.png")}
                            alt="docs_qa"
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Querying Tabular Data</h5>
                          <p>
                            Reduces a larger piece of content into its key
                            points or essence while retaining its core meaning
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item accordian_item">
                <h2 className="accordion-header accordian_header">
                  <button
                    className="accordion-button accordion_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseten"
                    aria-expanded="true"
                    aria-controls="collapseten"
                  >
                    <img
                      src={require("../../../assets/images/Usecases_10.png")}
                      alt=""
                    />{" "}
                    API Integration
                  </button>
                </h2>
                <div
                  id="collapseten"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body accordion_body">
                    <div className="product_card_container">
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Blue_1.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Black_1.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Machine Learning APIs</h5>
                          <p>
                            Predefined machine learning APIs can be used to
                            train own models using the platform's resources, or
                            pre-trained models can be used directly that are
                            provided by the platform.
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Blue_2.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Black_2.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Natural Language Processing APIs</h5>
                          <p>
                            Provides access to natural language processing
                            services, which can analyze text for sentiment,
                            extract key phrases, classNameify text into
                            categories, translate text between languages, and
                            more
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Blue_3.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Black_3.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Speech Recognition APIs</h5>
                          <p>
                            These APIs can convert spoken language into written
                            text, useful for transcribing audio, enabling voice
                            control in applications, and more
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Blue_4.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Black_4.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Image & Video Recognition APIs</h5>
                          <p>
                            These APIs can analyze images or videos to identify
                            objects, people, text, or actions
                          </p>
                        </div>
                      </div>
                      <div className="product_card">
                        <div className="img_holder">
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Blue_5.png")}
                            alt="docs_qa"
                          />
                          <img
                            src={require("../../../assets/images/Home_APIIntegrationt_Black_5.png")}
                            alt=""
                          />
                        </div>
                        <div className="product_card_body">
                          <h5>Chatbot APIs</h5>
                          <p>
                            These APIs can be used to integrate the chatbot with
                            their own applications or with third-party services
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeProduct;
