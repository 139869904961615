
import classes from './OwnModalFoundationalChips.module.css';
const OwnModalFoundationalChips = ({ storedChips, subList, chipsHandler }) => {
  let subChips = [];
  const chipsOnClick = (subList) => {
    chipsHandler(subList)
  }
  if (subList["subCategory"]) {
    subChips = subList.subCategory.map((sub) => sub.name)
  } else if (subList["subCategoryList"]) {
    subChips = subList.subCategoryList.map((sub) => sub)
  }


  return (
    <ul className={classes.subListChips_list}>
      {
        subChips.map((sub) => {
          return (
            <li className={classes.subListChips_item} key={sub}>
              <button className={`${storedChips.includes(sub) ? classes.active : ''}`} onClick={chipsOnClick.bind(null, sub)}>
                {sub}
              </button>
            </li>
          )
        })
      }
    </ul>
  )
}

export default OwnModalFoundationalChips;