import { useState, useRef } from 'react';
import OwnModalFormHeader from 'pi-screen/own_modal/OwnModalUI/OwnModalFormHeader';
import OwnModalSubmitBtn from 'pi-screen/own_modal/OwnModalUI/OwnModalSubmitBtn';
import classes from './StoreDataOtherModal.module.css';
const StoreDataOtherModal = ({ question, closeHandler, submitHandler, modal }) => {
  const input = useRef();
  const [storeDataOther, setStoreDataOther] = useState('');

  const modalClose = () => {
    closeHandler()
  }
  const formSubmitHandler = (e) => {
    e.preventDefault()
    if (storeDataOther && storeDataOther.trim() !== '') {
      submitHandler(question, storeDataOther, modal)
    } else {
      input.current.focus()
    }
  }
  const inputOnchange = (e) => {
    setStoreDataOther(e.target.value)
  }

  return (
    <div className={classes.storeData_Modal}>
      <OwnModalFormHeader heading="Others" closeHandler={modalClose} />
      <form className={classes.storeData_Form} onSubmit={formSubmitHandler}>
        <div className={classes.storeData_formGroup}>
          <label htmlFor='otherModal'>Where do you want to store your data?</label>
          <input ref={input} type='text' id='otherModal' value={storeDataOther} onChange={inputOnchange} autoFocus />
        </div>
        <OwnModalSubmitBtn name="Okay" extraClass={null} />
      </form >
    </div >
  )
}

export default StoreDataOtherModal;