import { useState } from 'react';
import OwnModalContext from './ownModalContext';

const intialValue = [
  // {
  //   ques: "Company name",
  //   question: 'Please provide your company name',
  //   value: ''
  // },
  // {
  //   ques: "Industries",
  //   question: 'Select the industries applicable to you',
  //   option: []
  // },
  // {
  //   ques: "Business",
  //   question: 'What business problem would you like to resolve?',
  //   option: []
  // },
  // {
  //   ques: "Hosting services",
  //   question: 'Select the cloud platforms for hosting your services',
  //   option: []
  // },
  // {
  //   ques: "foundational model(s)",
  //   question: 'Select the foundational model(s)',
  //   option: [],
  // }
]

const OwnModalContextProvider = (props) => {

  const [intialDatas, setIntialDatas] = useState(intialValue);

  const foundationModalValueSetter = (categoryData, pcategory, subCategory, question, subList = null) => {
    const settingData = () => {
      if (pcategory !== null) {
        if (intialDatas.length === 0) {
          const foundationalObj = {
            question: question,
            option: [
              {
                name: categoryData.name,
                category: [{
                  name: pcategory,
                  subCategory: [{
                    name: subList !== null ? subList.name : subCategory,
                    subList: subList !== null ? [subCategory] : []
                  }]
                }]
              }
            ]
          }
          return [foundationalObj]
        }
        if (intialDatas.length !== 0) {
          const existingModalIndex = intialDatas.findIndex((existModal) => existModal.question === question);
          const existingModal = intialDatas[existingModalIndex];
          if (existingModal) {
            const existingMainCategoryIndex = existingModal.option.findIndex((existModal) => existModal.name == categoryData.name);
            const existingMainCategory = existingModal.option[existingMainCategoryIndex];

            if (existingMainCategory) {
              const spreadedCategories = [...existingMainCategory.category];
              const existingCategoryIndex = spreadedCategories.findIndex((spreadCat) => spreadCat.name === pcategory);
              const existingCategory = spreadedCategories[existingCategoryIndex];
              if (existingCategory) {
                const spreadedSubCategories = [...existingCategory.subCategory];
                if (subList === null) {
                  const existingSubCategoryIndex = spreadedSubCategories.findIndex((spreadSub) => spreadSub.name === subCategory);
                  const existingSubCategory = spreadedSubCategories[existingSubCategoryIndex];
                  if (existingSubCategory) {
                    const forFilteredSubCateries = [...spreadedSubCategories]
                    const filteredSubCategories = forFilteredSubCateries.filter((subCat) => subCat.name !== subCategory);
                    if (filteredSubCategories.length === 0) {
                      const forFilteredCategoreies = [...spreadedCategories];
                      const filteredCategoreis = forFilteredCategoreies.filter((filCat) => filCat.name !== pcategory);
                      if (filteredCategoreis.length === 0) {
                        const forFilterOptions = [...existingModal.option];
                        const filteredOptions = forFilterOptions.filter((optFilt) => optFilt.name !== categoryData.name);
                        if (filteredOptions.length === 0) {
                          const forFilterModals = [...intialDatas];
                          const tempPrevData = forFilterModals.filter((filtModal) => filtModal.question !== question);
                          return tempPrevData;
                        }
                        if (filteredOptions.length !== 0) {
                          const tempPrevData = [...intialDatas];
                          tempPrevData[existingModalIndex].option = filteredOptions;
                          return tempPrevData;
                        }
                      }

                      if (filteredCategoreis.length !== 0) {
                        const tempPrevData = [...intialDatas];
                        tempPrevData[existingModalIndex].option[existingMainCategoryIndex].category = filteredCategoreis;
                        return tempPrevData;
                      }
                    }
                    if (filteredSubCategories.length !== 0) {
                      const tempPrevData = [...intialDatas];
                      tempPrevData[existingModalIndex].option[existingMainCategoryIndex].category[existingCategoryIndex].subCategory = filteredSubCategories;
                      return tempPrevData;
                    }
                  }
                  if (!existingSubCategory) {
                    const tempPrevData = [...intialDatas];
                    tempPrevData[existingModalIndex].option[existingMainCategoryIndex].category[existingCategoryIndex].subCategory.push({
                      name: subCategory,
                      subList: []
                    })
                    return tempPrevData;
                  }
                }
                if (subList !== null) {
                  const existingSubCategoryIndex = spreadedSubCategories.findIndex((spreadSub) => spreadSub.name === subList.name);
                  const existingSubCategory = spreadedSubCategories[existingSubCategoryIndex];
                  if (existingSubCategory) {
                    if (existingSubCategory.subList.includes(subCategory)) {
                      const forFilterSubList = [...existingSubCategory.subList];
                      const filteredSubList = forFilterSubList.filter((forFilSub) => forFilSub !== subCategory);
                      if (filteredSubList.length === 0) {
                        const forFilterSubCategory = [...spreadedSubCategories];
                        const filteredSubCategory = forFilterSubCategory.filter((forFilSub) => forFilSub.name !== subList.name);
                        if (filteredSubCategory.length === 0) {
                          const forFilCategoreis = [...existingMainCategory.category];
                          const filteredCategories = forFilCategoreis.filter((forFilCat) => forFilCat.name !== pcategory);
                          if (filteredCategories.length === 0) {
                            const forFilterOptions = [...existingModal.option];
                            const filteredOptions = forFilterOptions.filter((optFilt) => optFilt.name !== categoryData.name);
                            if (filteredOptions.length === 0) {
                              const forFilterModals = [...intialDatas];
                              const tempPrevData = forFilterModals.filter((filtModal) => filtModal.question !== question);
                              return tempPrevData;
                            }
                            if (filteredOptions.length !== 0) {
                              const tempPrevData = [...intialDatas];
                              tempPrevData[existingModalIndex].option = filteredOptions;
                              return tempPrevData;
                            }
                          }
                          if (filteredCategories.length !== 0) {
                            const tempPrevData = [...intialDatas];
                            tempPrevData[existingModalIndex].option[existingMainCategoryIndex].category = filteredCategories;
                            return tempPrevData;
                          }
                        }
                        if (filteredSubCategory.length !== 0) {
                          const tempPrevData = [...intialDatas];
                          tempPrevData[existingModalIndex].option[existingMainCategoryIndex].category[existingCategoryIndex].subCategory = filteredSubCategory;
                          return tempPrevData;
                        }
                      }
                      if (filteredSubList.length !== 0) {
                        const tempPrevData = [...intialDatas];
                        tempPrevData[existingModalIndex].option[existingMainCategoryIndex].category[existingCategoryIndex].subCategory[existingSubCategoryIndex].subList = filteredSubList;
                        return tempPrevData;
                      }
                    }
                    if (!existingSubCategory.subList.includes(subCategory)) {
                      const tempPrevData = [...intialDatas];
                      tempPrevData[existingModalIndex].option[existingMainCategoryIndex].category[existingCategoryIndex].subCategory[existingSubCategoryIndex].subList.push(subCategory);
                      return tempPrevData;
                    }
                  }
                  if (!existingSubCategory) {
                    const tempPrevData = [...intialDatas];
                    tempPrevData[existingModalIndex].option[existingMainCategoryIndex].category[existingCategoryIndex].subCategory.push({
                      name: subList.name,
                      subList: [subCategory]
                    })
                    return tempPrevData;
                  }
                }


              }
              if (!existingCategory) {
                const tempPrevData = [...intialDatas];
                const copyCategories = [...existingMainCategory.category];
                copyCategories.push({
                  name: pcategory,
                  subCategory: [{
                    name: subList !== null ? subList.name : subCategory,
                    subList: subList !== null ? [subCategory] : []
                  }]
                })
                tempPrevData[existingModalIndex].option[existingMainCategoryIndex].category = copyCategories;
                return tempPrevData;
              }
            }

            if (!existingMainCategory) {
              const tempPrevData = [...intialDatas];
              const existingOptions = [...existingModal.option];
              existingOptions.push({
                name: categoryData.name,
                category: [
                  {
                    name: pcategory,
                    subCategory: [{
                      name: subList !== null ? subList.name : subCategory,
                      subList: subList !== null ? [subCategory] : []
                    }]
                  }
                ]
              })
              tempPrevData[existingModalIndex].option = existingOptions;
              return tempPrevData;
            }

          }

          if (!existingModal) {
            const tempModals = [...intialDatas];
            tempModals.push({
              question: question,
              option: [
                {
                  name: categoryData.name,
                  category: [{
                    name: pcategory,
                    subCategory: [{
                      name: subList !== null ? subList.name : subCategory,
                      subList: subList !== null ? [subCategory] : []
                    }]
                  }]
                }
              ]
            })
            return tempModals;
          }
        }

      }

      if (pcategory === null) {
        if (intialDatas.length === 0) {
          const foundationalObj = {
            question: question,
            option: [
              {
                name: categoryData.name,
                subCategory: [subCategory]
              }
            ]
          }
          return [foundationalObj]
        }
        if (intialDatas.length !== 0) {
          const existingModalIndex = intialDatas.findIndex((existModal) => existModal.question === question);
          const existingModal = intialDatas[existingModalIndex];
          if (existingModal) {
            const existingMainCategoryIndex = existingModal.option.findIndex((existModal) => existModal.name == categoryData.name);
            const existingMainCategory = existingModal.option[existingMainCategoryIndex];

            if (existingMainCategory) {
              const copySubCategories = [...existingMainCategory.subCategory];
              if (copySubCategories.includes(subCategory)) {
                const filteredSubCategories = copySubCategories.filter((copySub) => copySub !== subCategory);
                if (filteredSubCategories.length === 0) {
                  const spreadedMainCategories = [...existingModal.option];
                  const filteredMainCategories = spreadedMainCategories.filter((spredMainCat) => spredMainCat.name !== categoryData.name);
                  if (filteredMainCategories.length === 0) {
                    let tempPrevData = [...intialDatas];
                    const spreadedExistingModels = [...intialDatas];
                    const filtedModals = spreadedExistingModels.filter((spreadedModal) => spreadedModal.question !== question);

                    tempPrevData = filtedModals;
                    return tempPrevData;
                  }
                  if (filteredMainCategories.length !== 0) {
                    const tempPrevData = [...intialDatas];
                    tempPrevData[existingModalIndex].option = filteredMainCategories;
                    return tempPrevData;
                  }

                }
                if (filteredSubCategories.length !== 0) {
                  const tempPrevData = [...intialDatas];
                  tempPrevData[existingModalIndex].option[existingMainCategoryIndex].subCategory = filteredSubCategories;
                  return tempPrevData;
                }
              }

              if (!copySubCategories.includes(subCategory)) {
                const tempPrevData = [...intialDatas];
                // console.log(tempPrevData[existingModalIndex].option[existingMainCategoryIndex].subCategory);
                const newSubCategories = [...copySubCategories, subCategory];
                console.log(newSubCategories);
                tempPrevData[existingModalIndex].option[existingMainCategoryIndex].subCategory = newSubCategories;

                return tempPrevData;
              }
            }

            if (!existingMainCategory) {
              const tempPrevData = [...intialDatas];
              const existingOptions = [...existingModal.option];
              existingOptions.push({
                name: categoryData.name,
                subCategory: [subCategory]
              })
              tempPrevData[existingModalIndex].option = existingOptions;
              return tempPrevData;
            }
          }

          if (!existingModal) {
            const tempModals = [...intialDatas];
            tempModals.push({
              question: question,
              option: [
                {
                  name: categoryData.name,
                  subCategory: [subCategory]
                }
              ]
            })
            return tempModals;
          }
        }
      }
    }
    const data = settingData()
    setIntialDatas(data);

  }
  const fileValueSetter = (question, activeTab, files) => {
    const settingFiles = () => {
      const prevIntialData = [...intialDatas];
      if (prevIntialData.length === 0) {
        const foundationalObj = {
          question: question,
          option: [
            {
              name: activeTab,
              files: [...files]
            }
          ]
        }
        return [foundationalObj];
      }
      if (prevIntialData.length !== 0) {
        const existingModalIndex = prevIntialData.findIndex((existingModal) => existingModal.question === question);
        const existingModal = prevIntialData[existingModalIndex];
        if (existingModal) {
          const existingOptions = [...existingModal.option];
          const existingMainCategoryIndex = existingOptions.findIndex((existMainCat) => existMainCat.name === activeTab);
          const existingMainCategory = existingOptions[existingMainCategoryIndex];
          if (existingMainCategory) {
            const spreadedModal = [...intialDatas];
            const existinfiles = existingMainCategory.files.map((exfile) => exfile.data);
            console.log(existinfiles);
            files.forEach((file) => {
              if (!existinfiles.includes(file.data)) {
                spreadedModal[existingModalIndex].option[existingMainCategoryIndex].files.push(file);
              }
            })
            return spreadedModal;
          }

          if (!existingMainCategory) {
            const spreadedModal = [...intialDatas];
            spreadedModal[existingModalIndex].option.push({
              name: activeTab,
              files: [...files]
            })
            return spreadedModal;
          }
        }
        if (!existingModal) {
          const prevExistingModal = [...intialDatas];
          const foundationalObj = {
            question: question,
            option: [
              {
                name: activeTab,
                files: [...files]
              }
            ]
          }
          prevExistingModal.push(foundationalObj);
          return prevExistingModal;
        }
      }

    }
    const data = settingFiles();
    setIntialDatas(data);
  }
  const fileDeleter = (id, question, tab) => {
    const fileSetter = () => {
      const prevIntialData = [...intialDatas];
      const existingModalIndex = prevIntialData.findIndex((existingModal) => existingModal.question === question);
      const existingModal = prevIntialData[existingModalIndex];
      if (existingModal) {
        const existingOptions = [...existingModal.option];
        const existingMainCategoryIndex = existingOptions.findIndex((existMainCat) => existMainCat.name === tab);
        const existingMainCategory = existingOptions[existingMainCategoryIndex];
        const filteredFiles = existingMainCategory.files.filter((file) => file.id !== id);
        if (filteredFiles.length === 0) {
          const spreadedOptions = [...existingModal.option];
          const filteredOption = spreadedOptions.filter((spreadOPtion) => spreadOPtion.name !== tab);
          if (filteredOption.length === 0) {
            const forFilterModals = [...intialDatas];
            const filteredModals = forFilterModals.filter((existModal) => existModal.question !== question);
            return filteredModals;
          }

          if (filteredOption.length !== 0) {
            const spreadedModals = [...intialDatas];
            spreadedModals[existingModalIndex].option = filteredOption;
            return spreadedModals;
          }
        }
        if (filteredFiles.length !== 0) {
          const spreadedModals = [...intialDatas];
          spreadedModals[existingModalIndex].option[existingMainCategoryIndex].files = filteredFiles;
          return spreadedModals;
        }

      }
    }
    const data = fileSetter();
    setIntialDatas(data);
  }
  const normalOptionHandler = (question, option) => {
    const setingData = () => {
      if (intialDatas.length !== 0) {
        const existintingQuestionModalIndex = intialDatas.findIndex((prevData) => prevData.question === question);
        const existingQuestionModal = intialDatas[existintingQuestionModalIndex];
        if (existingQuestionModal) {
          const tempArr = [...intialDatas];
          const existingModalOptionNames = existingQuestionModal.option.map((opt) => opt.name);
          if (existingModalOptionNames.includes(option)) {
            const tempFilteredData = tempArr[existintingQuestionModalIndex].option.filter((opt) => opt.name !== option);
            if (tempFilteredData.length === 0) {
              const filteredExistingModal = intialDatas.filter((intData) => intData.question !== question);
              return filteredExistingModal;
            } else {
              tempArr[existintingQuestionModalIndex].option = tempFilteredData;
              return tempArr;
            }
          } else {
            tempArr[existintingQuestionModalIndex].option.push({ name: option });
            return tempArr;
          }
        } else {
          const tempArr = [...intialDatas];
          const questionModalObj = {
            question: question,
            option: [{ name: option }]
          }
          tempArr.push(questionModalObj);
          return tempArr;
        }
      } else {
        return [{ question: question, option: [{ name: option }] }]
      }


    }
    const temp = setingData();
    setIntialDatas(temp)
  }
  const company_NameHandler = (question, name) => {
    setIntialDatas((prevData) => {
      if (prevData.length !== 0) {
        const existintingQuestionModalIndex = prevData.findIndex((prevData) => prevData.question === question);
        const existingQuestionModal = prevData[existintingQuestionModalIndex];
        const tempArr = [...prevData];
        if (existingQuestionModal) {
          if (name.trim() !== '') {
            tempArr[existintingQuestionModalIndex].name = name;
            return tempArr;
          }
          if (name.trim() === '') {
            const prevIntialData = [...intialDatas];
            const filteredIntialData = prevIntialData.filter((prevInt) => prevInt.question !== question);
            // tempArr[existintingQuestionModalIndex].name = name;
            // return tempArr;
            return filteredIntialData;
          }

        } else {
          tempArr.push({
            question: question,
            name: name
          })
          return tempArr;
        }
      } else {
        return [{
          question: question,
          name: name
        }]
      }
      return prevData;
    })
  }
  const dataSourceSetter = (question, option) => {
    const settingData = () => {
      if (typeof option !== 'string') {
        if (intialDatas.length !== 0) {
          const existintingQuestionModalIndex = intialDatas.findIndex((prevData) => prevData.question === question);
          const existingQuestionModal = intialDatas[existintingQuestionModalIndex];
          if (existingQuestionModal) {
            const tempArr = [...intialDatas];
            const existingModalOptionNames = existingQuestionModal.option.map((opt) => opt.name);
            if (existingModalOptionNames.includes(option)) {
              const tempFilteredData = tempArr[existintingQuestionModalIndex].option.filter((opt) => opt.name !== option.name);
              if (tempFilteredData.length === 0) {
                const filteredExistingModal = intialDatas.filter((intData) => intData.question !== question);
                return filteredExistingModal;
              } else {
                tempArr[existintingQuestionModalIndex].option = tempFilteredData;
                return tempArr;
              }
            } else {
              tempArr[existintingQuestionModalIndex].option.push({ name: option.name, subOptions: option.subOptions });
              return tempArr;
            }
          } else {
            const tempArr = [...intialDatas];
            const questionModalObj = {
              question: question,
              option: [{ name: option.name, subOptions: option.subOptions }]
            }
            tempArr.push(questionModalObj);
            return tempArr;
          }
        } else {
          return [{ question: question, option: [{ name: option.name, subOptions: option.subOptions }] }]
        }
      }
      if (typeof option === 'string') {
        if (intialDatas.length !== 0) {
          const existintingQuestionModalIndex = intialDatas.findIndex((prevData) => prevData.question === question);
          const existingQuestionModal = intialDatas[existintingQuestionModalIndex];
          if (existingQuestionModal) {
            const tempArr = [...intialDatas];
            const existingModalOptionNames = existingQuestionModal.option.map((opt) => opt.name);
            if (existingModalOptionNames.includes(option)) {
              const tempFilteredData = tempArr[existintingQuestionModalIndex].option.filter((opt) => opt.name !== option);
              if (tempFilteredData.length === 0) {
                const filteredExistingModal = intialDatas.filter((intData) => intData.question !== question);
                return filteredExistingModal;
              } else {
                tempArr[existintingQuestionModalIndex].option = tempFilteredData;
                return tempArr;
              }
            } else {
              tempArr[existintingQuestionModalIndex].option.push({ name: option });
              return tempArr;
            }
          } else {
            const tempArr = [...intialDatas];
            const questionModalObj = {
              question: question,
              option: [{ name: option }]
            }
            tempArr.push(questionModalObj);
            return tempArr;
          }
        } else {
          return [{ question: question, option: [{ name: option }] }]
        }
      }
      // return [...intialDatas];
    }
    const data = settingData();
    setIntialDatas(data)
  }

  // const radioDataSetter = (question, value) => {

  // }

  const value = {
    formDetail: intialDatas,
    foundationalHandler: foundationModalValueSetter,
    fileHandler: fileValueSetter,
    fileDeleteHandler: fileDeleter,
    normalOptionHandler: normalOptionHandler,
    companyNameHandler: company_NameHandler,
    dataSourceHandler: dataSourceSetter,
    // radioInputHandler: radioDataSetter
  };

  console.log(value);
  return (
    <OwnModalContext.Provider value={value}>
      {props.children}
    </OwnModalContext.Provider>
  )
}

export default OwnModalContextProvider;