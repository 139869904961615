import { useContext } from 'react';
import { OwnModalContext } from 'pi-screen/own_modal/context/ownModalContext';
import classes from './OwnModalFoundationalHeadList.module.css';
const OwnModalFoundationalHeadList = ({ tabList, activeTab, tabHandler, question }) => {
  const ctx = useContext(OwnModalContext);

  const existingFoundationalDataIndex = ctx.formDetail.findIndex((founData) => founData.question === question);
  const existingFoundationalData = ctx.formDetail[existingFoundationalDataIndex];
  const tabCounter = {}
  if (existingFoundationalData) {

    existingFoundationalData.option.forEach((founData) => {
      if (founData["category"]) {
        tabCounter[founData.name] = founData.category.length;
      } else if (founData["subCategory"]) {
        tabCounter[founData.name] = founData.subCategory.length;
      }
    })
  }
  const mainTabOnClickHandler = (tabName) => {
    tabHandler(tabName)
  }
  return (
    <ul className={classes.foundational_tab_head_list}>
      {
        tabList.map((liHeader) => {
          return (
            <li className={classes.foundational_tab_head_item} key={liHeader.name}>
              <button className={activeTab === liHeader.name ? classes.active : undefined}
                onClick={mainTabOnClickHandler.bind(null, liHeader.name)}>{liHeader.name}{liHeader.name !== "Bring your own model" ? tabCounter[liHeader.name] ? `(${tabCounter[liHeader.name]})` : "(0)" : undefined}</button>
            </li>
          )
        })
      }
    </ul>
  )
}

export default OwnModalFoundationalHeadList;