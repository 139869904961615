import React, { useMemo } from 'react';
import classes from './ownModalWrapper.module.css';
// import OwnModalBar from "./ownModalBar/ownModalBar"
import OwnModalContent from "./ownModalContent/ownModalContent";
const OwnMOdalWrapper = ({ data, pageNoter }) => {
  // const currentIndex = props.totalData.findIndex((exData) => exData[0].set === props.data[0].set);
  const settingData = useMemo(() => {
    return data
  })
  return (
    <div className={classes.ownModalWrapper_subContainer}>
      {   /*   <OwnModalBar data={props.data} length={props.totalData.length} activeIndex={currentIndex} pageIntializer={props.pageInializer} /> */}
      <OwnModalContent data={settingData} pageNoter={pageNoter} />
    </div>
  )
}

export default React.memo(OwnMOdalWrapper);