import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const UseCases = () => {
  const SimpleusecaseData = [
    {
      name: "QA Over Docs",
      details:
        " Reads, understands and extracts relevant information from text documents using machine learning models",
      linkto: "qadocs",
    },
    {
      name: "Summarization",
      details:
        "    Reduces a larger piece of content into its key points or essence while retaining its core meaning",
      linkto: "summarization",
    },
    {
      name: "Text to Image/Pdf",
      details:
        "Converts data into a visual format (like an image) or a document format (like a PDF)",
      linkto: "Text_To_Image",
    },
    {
      name: "Text to Video",
      details: "Creates video content based on textual input",
      linkto: "Text_To_Video",
    },
    {
      name: "Video",
      details: "Processes video contents to provide meaningful outputs",
      linkto: "video",
    },
    {
      name: "Agent Simulation",
      details:
        " Creates artificial agents that interact with a simulated environment and are programmed to perform tasks or make decisions based on certain rules or learning algorithms",
      linkto: "Agent Simulation",
    },
    {
      name: "Chatbots & Personal Assistant",
      details:
        " Interacts with humans in a natural, conversational manner using natural language processing (NLP) and machine learning techniques to understand and respond to user inputs",
      linkto: "Chatbots & Personal Assistant",
    },
    {
      name: "Autonomous Agents",
      details:
        "  Operates independently, make decisions, and perform tasks without the need for continuous human guidance",
      linkto: "Autonomous Agents",
    },
    {
      name: "Querying Tabular Data",
      details: " ",
      linkto: "Querying Tabular Data",
    },
    {
      name: "API Integration",
      details:
        "   Use of application programming interfaces (APIs) to  connect AI capabilities with other software applications  or services. APIs define the methods and data formats that software components should use to communicate with each other. ",
      linkto: "API Integration",
    },
  ];
  const EnterprisesUseCaseData = [
    {
      name: "Multi Models",
      details:
        " Reads, understands and extracts relevant information from text documents using machine learning models",
      linkto: "qadocs",
    },
    {
      name: "Labeling",
      details:
        "  Reduces a larger piece of content into its key points or essence while retaining its core meaning",
      linkto: "summarization",
    },
  ];
  const enterpriseButton = useRef();
  useEffect(() => {
    let tabactive = localStorage.getItem("usecaseTab");
    if (tabactive === "Enterprises Use Cases") {
      enterpriseButton.current.click();
      localStorage.removeItem("usecaseTab");
    }
  }, []);
  return (
    <div className="use_case_ex_container">
      <div className="usecase_tab_Container">
        <div className="usecase_tab_btn_container">
          <div className="nav-tabs nav_tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active"
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
            >
              Simple Use Cases
            </button>
            <button
              className="nav-link"
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="button"
              data-target="Enterprises Usecases"
              ref={enterpriseButton}
            >
              Enterprises Use Cases
            </button>
          </div>
        </div>
        <div
          className="tab-content product_content_container"
          id="nav-tabContent"
        >
          <div className={`tab-pane fade show active`} id="nav-home">
            <div className="use_case_ex_card_container">
              {SimpleusecaseData.map((item, i) => (
                <div className="use_case_ex_card" key={i}>
                  <Link to={item.linkto} className="usecase_Card_holder">
                    <div className="use_case_card_header">
                      <div className="img_holder">
                        <img
                          src={require(`../../assets/images/SimpleUsecases_Ask_${
                            i + 1
                          }.png`)}
                          alt="QA_black_icon"
                        />
                        <img
                          src={require(`../../assets/images/SimpleUsecases_Blue_${
                            i + 1
                          }.png`)}
                          alt="QA_black_icon"
                        />
                      </div>
                    </div>
                    <div className="use_case_card_body">
                      <h3>{item.name}</h3>
                      <p>{item.details}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="tab-pane fade show" id="nav-profile">
            <div className="use_case_ex_card_container">
              {EnterprisesUseCaseData.map((item, i) => (
                <div className="use_case_ex_card" key={i}>
                  <Link
                    to={`EnterprisesUsecases/${item.name}`}
                    className="usecase_Card_holder"
                  >
                    <div className="use_case_card_header">
                      <div className="img_holder">
                        <img
                          src={require(`../../assets/images/EnterpriseUsecases_Ask_${
                            i + 1
                          }.png`)}
                          alt="QA_black_icon"
                        />
                        <img
                          src={require(`../../assets/images/EnterpriseUsecases_Blue_${
                            i + 1
                          }.png`)}
                          alt="QA_black_icon"
                        />
                      </div>
                    </div>
                    <div className="use_case_card_body">
                      <h3>{item.name}</h3>
                      <p>{item.details}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
