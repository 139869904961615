import React from "react";
import { Outlet } from "react-router";
import PiNav from "../pi-navs/pi_usecase_nav";
const NonSubnavLayout = () => {
  return (
    <>
      <PiNav />

      <Outlet />
    </>
  );
};

export default NonSubnavLayout;
