import ErrorLayout from "pi-layout/ErrorLayout";
import PageNotFound from "pi-screen/page_not_found";

const ErrorRoute = {
    path: "/",
    element: <ErrorLayout />,
    children: [

        {
          path: "*",
          element: <PageNotFound />,
        },
    ],
  };
  
  export default ErrorRoute;